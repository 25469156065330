<div class="login-view" *ngIf="!identityProvider.getIdentity().isAuthenticated">
	<div class="login-view__wrapper animated fadeInUp">
		<div class="login-view__logo">
			<img src="/assets/images/rp.png" alt="RP" style="width:150px">
		</div>
		<div class="login-view__title">{{header}}</div>
		<div class="login-view__subtitle">{{subtitle}}</div>
		<ng-content></ng-content>
	</div>
	<div class="login-view__icons-wrapper">
		<svg-icon [icon]="svgIconNames.Doctor" class="login-view__icon icon-1"></svg-icon>
		<svg-icon [icon]="svgIconNames.Law" class="login-view__icon icon-2"></svg-icon>
		<svg-icon [icon]="svgIconNames.Medical" class="login-view__icon icon-3"></svg-icon>
		<svg-icon [icon]="svgIconNames.Manager" class="login-view__icon icon-4"></svg-icon>
		<svg-icon [icon]="svgIconNames.Clients" class="login-view__icon icon-5"></svg-icon>
		<svg-icon [icon]="svgIconNames.MedicalFacility" class="login-view__icon icon-6"></svg-icon>
		<svg-icon [icon]="svgIconNames.Call" class="login-view__icon icon-2"></svg-icon>
		<svg-icon [icon]="svgIconNames.Preferences" class="login-view__icon icon-1"></svg-icon>
		<svg-icon [icon]="svgIconNames.Search" class="login-view__icon icon-4"></svg-icon>
		<svg-icon [icon]="svgIconNames.Visit" class="login-view__icon icon-3"></svg-icon>
		<svg-icon [icon]="svgIconNames.Place" class="login-view__icon icon-6"></svg-icon>
		<svg-icon [icon]="svgIconNames.Clock" class="login-view__icon icon-5"></svg-icon>
	</div>
	<svg class="login-view__wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
		<path fill="#32c3a6" fill-opacity="1" d="M0,192L80,186.7C160,181,320,171,480,176C640,181,800,203,960,213.3C1120,224,1280,224,1360,224L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
	</svg>
	<svg class="login-view__wave login-view__wave--animate" style="animation-delay: 1s;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
		<path fill="#ffffff" fill-opacity="0.1" d="M0,128L80,144C160,160,320,192,480,181.3C640,171,800,117,960,112C1120,107,1280,149,1360,170.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
	</svg>
	<svg class="login-view__wave login-view__wave--animate" style="animation-delay: 2s;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
		<path fill="#ffffff" fill-opacity="0.1" d="M0,96L80,101.3C160,107,320,117,480,138.7C640,160,800,192,960,176C1120,160,1280,96,1360,64L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
	</svg>
	<svg class="login-view__wave login-view__wave--animate" style="animation-delay: 3s;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
		<path fill="#ffffff" fill-opacity="0.1" d="M0,256L80,218.7C160,181,320,107,480,106.7C640,107,800,181,960,229.3C1120,277,1280,299,1360,309.3L1440,320L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
	</svg>
	<div class="login-view__footer">
		Rockpoint Legal &copy; {{currentYear}}
	</div>
	<loading-spinner [show]="loading"
					 loadingLabel="Signing in. Please wait..."
					 [style]="{'background-color': 'rgba(67, 60, 88, 0.9)', 'z-index': 10}">
	</loading-spinner>
</div>
