<div class="documents-list__category__files__item" [attr.data-cy]="'file__#' + file.id">
	<div class="documents-list__category__files__item__header">
		<ng-container *ngIf="showRenameDialog$ | async; else fileHeader">
			<input class="base-input" data-cy="newName-input" [formControl]="fileNameControl">
			<div (click)="renameFile()" data-cy="submit-btn" class="action__item">
				<svg-icon [icon]="svgIconNames.Done" class="primary-color trash-icon"></svg-icon>
			</div>
			<div (click)="closeRenameDialog()" data-cy="cancel-btn" class="action__item">
				<svg-icon [icon]="svgIconNames.Clear" class="danger-color trash-icon"></svg-icon>
			</div>
		</ng-container>
		<ng-template #fileHeader>
			<div>
				<a (click)="downloadFile()" data-cy="fileName" class="documents-list__category__files__item__name">
					<svg-icon [icon]="svgIconNames.Attachment" class="svg-icon"></svg-icon>
					{{ file.originalTitle }}
				</a>
				<div class="documents-list__category__files__item__properties">
				<span data-cy="uploadInfo">
					<ng-container *ngIf="file.uploadedOn">{{ file.uploadedOn | date }}</ng-container>
					<ng-container
						*ngIf="file.uploadedBy"> by {{ file.uploadedBy.firstName }} {{ file.uploadedBy.lastName }}</ng-container>
				</span>
					<span *ngIf="file.label" style="margin-left: 4px;" data-cy="fileLabel"
						  class="badge badge-primary">{{ file.label }}</span>
				</div>
			</div>
			<div class="documents-list__category__files__item__actions">
				<ng-container *ngrxLet="responsiveService.isMobile$; let isMobile">
					<div (click)="triggerRenameDialog()" data-cy="openRenameDialog-btn" class="action__item"
						 [tooltip]="isMobile ? '' : 'Edit name'" placement="bottom">
						<fa-icon [icon]="faIcons.pencilAlt"></fa-icon>
					</div>
					<div (click)="onClickRemove()" data-cy="openRemoveDialog-btn" class="action__item"
						 [tooltip]="isMobile ? '' : 'Remove'" placement="bottom">
						<svg-icon [icon]="svgIconNames.Trash" class="trash-icon"></svg-icon>
					</div>
				</ng-container>
			</div>
			<div *ngIf="showConfirmationDialog$ | async" class="documents-list__category__files__item__confirmation"
				 @fadeInOut>
				Are you sure?
				<div class="confirm" data-cy="submit-btn" (click)="removeFile()">Yes</div>
				<div class="cancel" data-cy="cancel-btn" (click)="cancel()">Cancel</div>
			</div>
		</ng-template>
	</div>
</div>
<download-link [config$]="downloadLink$"></download-link>
