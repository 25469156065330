import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AddressMarker } from '@app/shared/model/address-marker';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { BooleanFilterVisitor } from '@app/shared/filter-visitors/boolean-filter-visitor';
import { IdFilterVisitor } from '@app/shared/filter-visitors/id-filter-visitor';
import { FilterVisitor } from '@app/shared/filter-visitors/models/filter-visitor';
import { PricingFilterVisitor } from '@app/shared/filter-visitors/pricing-filter-visitor';
import { StringFilterVisitor } from '@app/shared/filter-visitors/string-filter-visitor';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { MedicalFacility, MedicalFacilityCodelist } from '@app/shared/model/types/medical-facility';
import { PlacesAutocompleteAddress } from '@app/shared/model/types/places-autocomplete-address';
import { MedicalMarkersMapFilterService } from '@app/widgets/medical-markers-map/service/medical-markers-map-filter.service';
import { MedicalMarkersMapHelperService } from '@app/widgets/medical-markers-map/service/medical-markers-map-helper.service';
import { map, startWith, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'medical-markers-map-medical-facility-filter',
	templateUrl: './medical-markers-map-medical-facility-filter.component.html',
	providers: [MedicalMarkersMapFilterService]
})
export class MedicalMarkersMapMedicalFacilityFilterComponent implements OnInit, OnDestroy {
	@Output() onMedicalFacilityMarkersCreated = new EventEmitter<AddressMarker[]>();
	formControls: { [key: string]: FormControl<any> } = {};
	readonly othersOption = [{ id: '-1', title: 'Others' }];
	readonly medicalFacilityTier = NonUrlCodelistName.DoctorAndMedicalFacilityTier;
	private readonly medicalFacilityTypeIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
	private destroy$ = new Subject<void>();

	constructor(
		private medicalFacilityFilterService: MedicalMarkersMapFilterService<MedicalFacility>,
		private medicalMarkersMapHelper: MedicalMarkersMapHelperService,
		private codelistRepository: CodelistRepositoryService,) {
	}

	public ngOnInit(): void {
		const filters: Record<string, FilterVisitor> = {
			medicalFacilityType: new IdFilterVisitor('medicalFacilityType'),
			loaType: new IdFilterVisitor<MedicalFacility>('loaType'),
			rpCost: new PricingFilterVisitor<MedicalFacility>('rpCost'),
			active: new BooleanFilterVisitor<MedicalFacility>('active'),
			city: new StringFilterVisitor<MedicalFacility>('city'),
			zip: new StringFilterVisitor<MedicalFacility>('zip'),
			tier: new IdFilterVisitor<MedicalFacility>('tier')
		}

		Object.entries(filters).forEach(([key, filter]) => {
			this.formControls[key] = filter.getFormControl();
		});

		this.medicalFacilityFilterService.addFilters(Object.values(filters));

		const medicalFacilities$ = this.codelistRepository.getByUrl<MedicalFacilityCodelist[]>(UrlCodelistLink.MedicalFacilities)
			.pipe(
				map((medicalFacilities) => medicalFacilities
					.filter((medicalFacility) => medicalFacility.addressLat && medicalFacility.addressLng)),
				this.medicalFacilityFilterService.applyFilter()
			);

		medicalFacilities$.pipe(
			map((filteredMedicalFacilities) => filteredMedicalFacilities
				.filter((medicalFacility) => medicalFacility.addressLat && medicalFacility.addressLng)
				.map((medFacility) => this.medicalMarkersMapHelper.createMarker(
					medFacility.name,
					{
						icon: medFacility.medicalFacilityType?.mapMarker ?? this.medicalFacilityTypeIcon,
						cursor: 'pointer'
					},
					medFacility
				))),
			startWith([]),
			takeUntil(this.destroy$)
		).subscribe((markers) => {
			this.onMedicalFacilityMarkersCreated.next(markers);
		});
	}

	public onRpCostChanged(rpCost: string): void {
		this.formControls.rpCost.setValue(+rpCost);
	}

	public setLocation(autoCompleteAddress: PlacesAutocompleteAddress): void {
		this.formControls.city.setValue(autoCompleteAddress.city);
		this.formControls.zip.setValue(autoCompleteAddress.zip);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
