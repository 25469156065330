import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CurrentPortfolioResponse, OutstandingGrouped } from '@app/dashboard/current-portfolio/current-portfolio.model';
import { DashboardFilterStore } from '@app/dashboard/service/dashboard-filter-store.service';
import { DashboardRoundStore } from '@app/dashboard/service/dashboard-round-store.service';
import { dateBoundaryOperator } from '@app/dashboard/shared/date-boundary-operator';
import { GhostElementService } from '@app/widgets/ghost-element/ghost-element.service';
import { combineLatest, merge, Observable, of, switchMap, filter, map, shareReplay, tap } from 'rxjs';

@Component({
	selector: 'current-portfolio',
	templateUrl: './current-portfolio.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ['h1::first-letter {padding-right: 0.3em;}'],
	styleUrls: ['../shared/dashboard-date-filter-styling.scss'],
	providers: [GhostElementService]
})
export class CurrentPortfolioComponent implements OnInit {
	@Input() isCollapseManaged = false;

	portfolio$: Observable<CurrentPortfolioResponse>;
	projection$: Observable<OutstandingGrouped[]>;
	round$: Observable<boolean>;
	dateFilterControl = new FormControl<Date>(null);

	preSetMultiplier = new FormControl(1.7);
	medicalMultiplier = new FormControl(2.0);

	constructor(
		private http: HttpClient,
		private ghostVisibilityService: GhostElementService,
		private dashboardFilterStore: DashboardFilterStore,
		private dashboardRoundStore: DashboardRoundStore) {
	}

	public ngOnInit(): void {
		this.round$ = this.dashboardRoundStore.selectRound$;
		const portfolioGetter$ = combineLatest([
			this.dashboardFilterStore.fundingParams$,
			this.dateFilterControl.valueChanges.pipe(
				filter(() => this.dateFilterControl.valid),
				dateBoundaryOperator
			)
		]).pipe(
			tap(() => {
				this.ghostVisibilityService.showData(false);
			}),
			switchMap(([params, dateFilter]) => {
				if (dateFilter != null) {
					params = params.set('dateBoundary', dateFilter);
				}
				return this.http.get<CurrentPortfolioResponse>('/api/dashboard/current-portfolio', { params });
			}),
			tap((res) => {
				this.preSetMultiplier.patchValue(res.preSetExpectedFee);
				this.medicalMultiplier.patchValue(res.medicalExpectedFee);
				this.ghostVisibilityService.showData(true);
			}),
			shareReplay({ bufferSize: 1, refCount: true })
		);
		this.projection$ = combineLatest([
			portfolioGetter$,
			this.preSetMultiplier.valueChanges,
			this.medicalMultiplier.valueChanges,
		]).pipe(
				map(([portfolio, presetMultiplier, medicalMultiplier]) => {
					return portfolio.outstandingGrouped.map(
						(group) => this.getProjection(group, presetMultiplier, medicalMultiplier)
					);
				})
			);
		this.portfolio$ = merge(of(null), portfolioGetter$);
	}

	public getProjection(
		group: OutstandingGrouped,
		presetMultiplier: number,
		medicalMultiplier: number,
	): OutstandingGrouped {
		let multiplier: number;
		switch (group.fundingType) {
			case 'Medical':
				multiplier = medicalMultiplier;
				break;
			default:
				multiplier = presetMultiplier;
		}
		const fee = group.principal * (multiplier - 1);

		return {
			...group,
			fee,
			faceValue: group.principal + fee
		};
	}
}
