<public-view header="Forgotten password" [subtitle]="subtitle">
	<form class="login-view__form" role="form" data-cy="passwordForm" [formGroup]="passwordForm" (ngSubmit)="resetPassword()" autocomplete="off" #form="ngForm">
		<div class="login-view__form__input-wrapper" [ngClass]="{'error' : userEmail.invalid && form.submitted}">
			<input placeholder="Email" type="text" name="email" formControlName="email" data-cy="email" autocomplete="off">
			<fa-icon [icon]="faIcons.exclamationCircle"></fa-icon>
			<div *ngIf="userEmail.invalid && form.submitted" class="clue-info">
				<div *ngIf="userEmail.errors.required">Email is required</div>
				<div *ngIf="userEmail.errors.email">Must be an email.</div>
				<div *ngIf="userEmail.errors.maxlength">Maximum length is 255.</div>
			</div>
		</div>
		<div class="login-view__form__forgot-password">
			<a [routerLink]="['/login']">Back to Login</a>
		</div>
		<button class="login-view__form__button animated" data-cy="submit-btn" (animationend)="shake = false" [ngClass]="{'shake': shake}" type="submit">
			Request new password
		</button>
	</form>
</public-view>
