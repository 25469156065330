import { Injectable } from '@angular/core';
import { ApprovalStatus } from '@app/shared/model/constants/approval-status';
import { FundingTypeCategory } from '@app/shared/model/constants/funding-type-category';
import { FundsDeliveryOptionSystemCodes } from '@app/shared/model/types/codelists/funds-delivery-option';
import { boardRoutesMapping } from '@app/w-boards/models/board-routes-mapping';
import { BoardColumns, BoardTypeModel } from '@app/w-boards/models/board-type.model';
import { UnderwritingColumnIds } from '@app/w-boards/models/constants/underwriting-columns-constant';
import { FundingsCount } from '@app/w-boards/models/fundings-count';
import { BoardFilterService } from '@app/w-boards/services/board-filter.service';
import { loadFundings } from '@app/w-boards/store/actions/board.actions';
import { MemoizedSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class BoardHelperService {

	constructor(
		private store: Store,
		private boardFilter: BoardFilterService
	) {
	}

	public loadBoards(board: string): void {
		this.store.dispatch(loadFundings({ board }));
	}

	public getFundingsCount$(selector: MemoizedSelector<any, any[]>): Observable<FundingsCount> {
		return this.store.select(selector)
			.pipe(
				this.boardFilter.applyFilter(),
				withLatestFrom(this.store.select(selector)),
				map(([filteredFundings, allFundings]) => ({
					all: allFundings.length,
					filtered: filteredFundings.length
				})),
			);
	}

	public exportFundings$(selector: MemoizedSelector<any, any[]>): Observable<any> {
		return this.store.select(selector).pipe(
			this.boardFilter.applyFilter(),
			map((fundings) => fundings.map((funding) => funding.id)));
	}

	public resolveBoard(funding, board: string): any {
		try {
			const columns = BoardColumns[board];
			return { ...boardRoutesMapping[board], column: this.resolveBoardColumn(funding, board, columns) };
		} catch (error) {
			console.error(`Wrong board config for ${board}.
			FundingId: ${funding.id},
			WhiteboardStatus: ${JSON.stringify(funding.whiteboardStatus)},
			Boards: ${funding.boards}`);
			return null;
		}
	}

	private resolveBoardColumn(funding, board, columns): string {
		const columnId = Object.keys(funding.whiteboardStatus).find(status => columns[status]);
		let column: string;
		switch (board) {
			case BoardTypeModel.LawFirmManagerBoard:
				column = this.resolveLawFirmManagerBoardColumn(funding, columns, columnId);
				break;
			case BoardTypeModel.UnderwritingBoard:
				column = this.resolveUnderwritingBoardColumn(funding, columns, columnId);
				break;
			case BoardTypeModel.PaymentsBoard:
				column = this.resolvePaymentsBoardColumn(funding, columns);
				break;
			default:
				column = columns[columnId];

		}
		return column ?? '';
	}

	private resolveLawFirmManagerBoardColumn(funding, columns: any, columnId: string): string {
		if (columnId !== undefined) {
			return columns[columnId];
		}

		let fundingTypeColumns = null;
		let prefix;
		if (!funding.fundingType.medical) {
			fundingTypeColumns = columns.Preset;
			prefix = 'PreSet';
		} else if (funding?.fundingType?.category == FundingTypeCategory.Med && !funding?.fundingType?.isMri) {
			fundingTypeColumns = funding?.approvalStatus != ApprovalStatus.Consult ? columns.Medical : columns.MedicalScheduling;
			prefix = funding?.approvalStatus != ApprovalStatus.Consult ? 'Medical' : 'Medical Scheduling';
		} else if (funding.fundingType.isMri) {
			fundingTypeColumns = columns.Mri;
			prefix = 'MRI';
		} else if (funding.fundingType?.isCourtReporting) {
			fundingTypeColumns = columns.CourtReporting;
			prefix = 'Court Reporting';
		}
		const originalColumnName = this.resolveBoardColumn(funding, BoardTypeModel.LawFirmManagerBoard, fundingTypeColumns);
		return `${prefix}/${originalColumnName}`;
	}

	private resolveUnderwritingBoardColumn(funding, columns: any, columnId: string): string {
		if (typeof columns[columnId] === 'string') {
			return columns[columnId];
		}
		const fundingTypeColumns = columns[columnId];
		if (!funding.fundingType.medical && !funding.fundingType.isCaseCost) {
			return fundingTypeColumns.preset;
		} else if (funding.fundingType.isCaseCost) {
			return fundingTypeColumns.caseCost;
		} else if (
			funding.fundingType.category == FundingTypeCategory.Med
			&& funding.whiteboardStatus[UnderwritingColumnIds.ReadyForReview]
		) {
			return fundingTypeColumns.medical;
		} else if (funding.fundingType.category == FundingTypeCategory.CourtReporting) {
			return fundingTypeColumns.courtReporting;
		}
		return '';
	}

	private resolvePaymentsBoardColumn(funding, columns): string {
		if (funding.fundingType.isMri) {
			return columns.mri;
		}
		switch (funding.fundsDeliveryOption?.systemCode) {
			case FundsDeliveryOptionSystemCodes.FedEx:
				return columns.fedEx;
			case FundsDeliveryOptionSystemCodes.WesternUnion:
				return columns.westernUnion;
			case FundsDeliveryOptionSystemCodes.Wire:
				return columns.wire;
			case FundsDeliveryOptionSystemCodes.USPS:
				return columns.usps;
			case FundsDeliveryOptionSystemCodes.CreditCard:
				return columns.creditCard;
			case FundsDeliveryOptionSystemCodes.PickUp:
				return columns.pickUp;
			default:
				return columns.na;
		}
	}

	public getFundings$(path: MemoizedSelector<any, any>): Observable<any> {
		return this.store.select(path);
	}
}
