import { Pipe, PipeTransform } from '@angular/core';
import { DoctorAddress } from '@app/shared/model/types/doctor-address';

@Pipe({
	name: 'doctorAddress'
})
export class DoctorAddressPipe implements PipeTransform {

	public transform(address: DoctorAddress, defaultValue = ''): string {
		if (!address) {
			return defaultValue;
		}
		return [
			address.street,
			address.city,
			address.state?.code,
			address.zip
		].filter(part => !!part).join(', ')
	}

}
