import { ColDef } from 'ag-grid-community';
import { get } from 'lodash';
import * as moment from 'moment';

function dateComparator(dateA, dateB, includeTime = false): number {
	if (!dateA) {
		return -1;
	} else if (!dateB) {
		return 1;
	}
	const momentDateA = includeTime ? moment(dateB) : moment(dateB, 'YYYY-MM-DD');
	const momentDateB = includeTime ? moment(dateA) : moment(dateA, 'YYYY-MM-DD');

	if (momentDateA > momentDateB) {
		return -1;
	} else if (momentDateA < momentDateB) {
		return 1;
	} else {
		return 0;
	}
}

function dateFilterComparator(filterLocalDateAtMidnight, cellValue): number {
	const cellDate = moment(cellValue, 'YYYY-MM-DD').startOf('day');
	const filterDate = moment(filterLocalDateAtMidnight).startOf('day');

	if (cellDate < filterDate) {
		return -1;
	} else if (cellDate > filterDate) {
		return 1;
	} else if (cellDate.isSame(filterDate)) {
		return 0;
	} else {
		return null;
	}
}

export function excelDateFormatter(date, format = 'YYYY-MM-DD'): string {
	return date ? moment(date).format(format) : '';
}

export function dateColumnDefinition(field: string): ColDef {
	return {
		valueGetter: ({ data }) => excelDateFormatter(get(data, field, null)),
		valueFormatter: ({ value }) => value ? moment(value).format('MM/DD/YYYY') : '',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: dateFilterComparator,
			filterOptions: [
				{
					displayKey: 'dateBlank',
					displayName: '(Blanks)',
					predicate: (filterValues: any[], cellValue: any) => cellValue === '',
					numberOfInputs: 0,
				},
				'equals',
				'greaterThan',
				'lessThan',
				'notEqual',
				'inRange',
			],
			defaultOption: 'equals'
		},
		comparator: (dateA, dateB) => dateComparator(dateA, dateB, false),
		cellClass: 'dateType'
	};
}

export function dateTimeColumnDefinition(field: string): ColDef {
	return {
		valueGetter: ({ data }) => excelDateFormatter(get(data, field, null), 'YYYY-MM-DDTHH:mm:ss'),
		valueFormatter: ({ value }) => value ? moment(value).format('MM/DD/YYYY hh:mm a') : '',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: dateFilterComparator,
			filterOptions: [
				{
					displayKey: 'dateBlank',
					displayName: '(Blanks)',
					predicate: (filterValues: any[], cellValue: any) => cellValue === '',
					numberOfInputs: 0,
				},
				'equals',
				'greaterThan',
				'lessThan',
				'notEqual',
				'inRange',
			],
			defaultOption: 'equals'
		},
		comparator: (dateA, dateB) => dateComparator(dateA, dateB, true),
		cellClass: 'dateTimeType'
	};
}
