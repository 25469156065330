import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { AddressMarker } from '@app/shared/model/address-marker';
import { losAngelesLocation } from '@app/widgets/medical-markers-map/constants/location-constant';
import { BehaviorSubject, combineLatest, from, map, Observable, startWith, Subject } from 'rxjs';
import LatLng = google.maps.LatLng;
import LatLngLiteral = google.maps.LatLngLiteral;

@Component({
	selector: 'medical-markers-map',
	templateUrl: './medical-markers-map.component.html',
	styleUrls: ['./medical-markers-map.component.scss'],
})
export class MedicalMarkersMapComponent implements OnInit {
	@Input() additionalMarkers$?: Observable<AddressMarker[]> = from([]).pipe(startWith([]));
	@Input() center: LatLng | LatLngLiteral = losAngelesLocation;

	@ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
	selectedMarkers$: Subject<AddressMarker[]> = new Subject<AddressMarker[]>();
	markers$: Observable<AddressMarker[]>;

	readonly markerClustererImagePath =
		'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
	readonly maxZoom = 20;

	private doctorMarkers$: Subject<AddressMarker[]> = new BehaviorSubject([]);
	private medicalFacilityMarkers$: Subject<AddressMarker[]> = new BehaviorSubject([]);

	public ngOnInit(): void {
		window.console.warn = () => ''; //to hide warnings from external google maps library
		const allMarkers = [
			this.doctorMarkers$.asObservable(),
			this.medicalFacilityMarkers$.asObservable(),
			this.additionalMarkers$
		];

		this.markers$ = combineLatest(allMarkers).pipe(map((markers) => [].concat(...markers)));
	}

	public setMarkers(markers: AddressMarker[], doctorMarkers: boolean) {
		if (doctorMarkers) {
			this.doctorMarkers$.next(markers);
		} else {
			this.medicalFacilityMarkers$.next(markers);
		}
	}

	public openInfoWindow(markerScope: MapMarker, marker: AddressMarker): void {
		this.selectedMarkers$.next([marker]);
		this.infoWindow.open(markerScope);
	}

	public onClusterClick(mapCluster: any, mapMarkers: AddressMarker[]): void {
		const zoomFromMap = mapCluster.getMap().getZoom();
		const clusterMarkers = mapCluster.getMarkers();
		const markersToShow = clusterMarkers.map((marker) =>
			mapMarkers.find((mapMarker) => {
					const mapMarkerPosition = 'toJSON' in mapMarker.position ? mapMarker.position.toJSON() : mapMarker.position;
					return marker.getPosition().toJSON().lat == mapMarkerPosition.lat
						&& marker.getPosition().toJSON().lng == mapMarkerPosition.lng
						&& mapMarker.label.text === marker.getLabel().text;
				}
			)
		);

		if (zoomFromMap === this.maxZoom) {
			this.selectedMarkers$.next(markersToShow);
			this.infoWindow.position = mapCluster.getCenter();
			this.infoWindow.open();
		}
	}
}
