import { Applicant } from '@app/shared/model/types/applicant';
import { LoaType } from '@app/shared/model/types/codelists/loa-type';
import { MedicalFacilityType } from '@app/shared/model/types/codelists/medical-facility-type';
import { Doctor } from '@app/shared/model/types/doctor';
import { DoctorContact } from '@app/shared/model/types/doctor-contact';
import { DoctorSpecialization } from '@app/shared/model/types/codelists/doctor-specialization';
import { Funding } from '@app/shared/model/types/funding';
import { LawFirm } from '@app/shared/model/types/law-firm';
import { LawFirmStaff as LawFirmStaffEntity } from '@app/shared/model/types/law-firm-staff';
import { LawFirmTitle } from '@app/shared/model/types/codelists/law-firm-title';
import { MedicalFacility } from '@app/shared/model/types/medical-facility';
import { UsState } from '@app/shared/model/types/codelists/us-state';

export enum SmartSearchSection {
	Applicants = 'applicants',
	LawFirmStaff = 'lawFirmStaff',
	LawFirms = 'lawFirms',
	Doctors = 'doctors',
	MedicalFacilities = 'medicalFacilities',
	DoctorContacts = 'doctorContacts',
}

export enum SmartSearchState {
	Void = 'void',
	Collapsed = 'collapsed',
	Expanded = 'expanded',
}

export type SmartSearchSectionState = {
	[key in SmartSearchSection]: SmartSearchState;
};

export enum SmartSearchVisibleItemsCounts {
	Applicants = 7,
	LawFirmStaff = 5,
	LawFirms = 3,
	Doctors = 3,
	MedicalFacilities = 3,
	DoctorContacts = 3,
}

export type SmartSearchItem<T = unknown> = {
	name: string,
	entity: T
};

export type SmartSearchLastItem<T = unknown> = {
	index: number,
	item: SmartSearchItem<T>
};

export type SmartSearchApplicant = {
	id: Applicant['id'],
	internalId: Applicant['internalId'],
	firstName: Applicant['firstName'],
	lastName: Applicant['lastName'],
	archived: Applicant['archived'],
	fundings: Pick<Funding, 'id' | 'internalId' | 'approvalStatus' | 'archived' | 'preArchivedOn'>[],
	attorney: Pick<LawFirmStaffEntity, 'id' | 'firstName' | 'lastName' | 'highPriority'>
}

export type SmartSearchLawFirmStaff = {
	id: LawFirmStaffEntity['id'],
	firstName: LawFirmStaffEntity['firstName'],
	lastName: LawFirmStaffEntity['lastName'],
	phone: LawFirmStaffEntity['phone'],
	highPriority: LawFirmStaffEntity['highPriority'],
	lawFirm: Pick<LawFirm, 'id' | 'name'>,
	title: Pick<LawFirmTitle, 'id'>
}

export type SmartSearchLawFirm = {
	id: LawFirm['id'],
	name: LawFirm['name'],
	street: LawFirm['street'],
	city: LawFirm['city'],
	highPriorityFirm: LawFirm['highPriorityFirm'],
	state: Pick<UsState, 'id' | 'code' | 'title'>
}

export type SmartSearchDoctor = {
	id: Doctor['id'],
	name: Doctor['name'],
	specialization: Pick<DoctorSpecialization, 'id' | 'title'>
}

export type SmartSearchMedicalFacility = {
	id: MedicalFacility['id'],
	name: MedicalFacility['name'],
	loaType: Pick<LoaType, 'id' | 'title' | 'systemCode'>,
	medicalFacilityType: Pick<MedicalFacilityType, 'id' | 'title'>
}

export type SmartSearchDoctorContact = {
	id: DoctorContact['id'],
	name: DoctorContact['firstName']
	firstName: LawFirmStaffEntity['firstName'],
	lastName: LawFirmStaffEntity['lastName'],
	doctor: DoctorContact['doctor']
}

export type SmartSearchFundings = {
	id: Funding['id'],
	internalId: Funding['internalId'],
	applicant: Pick<Applicant, 'id' | 'internalId' | 'firstName' | 'lastName'>
}

export type SmartSearchResponse = {
	applicants: SmartSearchApplicant[],
	lawFirmStaff: SmartSearchLawFirmStaff[],
	lawFirms: SmartSearchLawFirm[],
	doctors: SmartSearchDoctor[],
	medicalFacilities: SmartSearchMedicalFacility[],
	fundings?: SmartSearchFundings[]
	doctorContacts?: SmartSearchDoctorContact[]
}
