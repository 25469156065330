import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeInOut } from '@app/shared/animations/animations';
import { NgIf, NgStyle } from '@angular/common';

@Component({
	selector: 'loading-spinner',
	animations: [
		fadeInOut
	],
	template: `
		<div class="loading-panel" *ngIf="show" data-cy="loading-element" @fadeInOut [ngStyle]="style">
			<div class="loading-panel__message">
				<div class="spinner">
					<div class="bounce1"></div>
					<div class="bounce2"></div>
					<div class="bounce3"></div>
				</div>
				<div class="loading-panel__message__text" *ngIf="loadingLabel !== ''">
					{{ loadingLabel }}
				</div>
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		NgStyle
	]
})
export class LoadingSpinnerComponent {
	@Input() loadingLabel = '';
	@Input() style;
	@Input() show: boolean;
}
