import { map, startWith, Subject, switchMap, take, timer } from 'rxjs';

export default class TimeoutTrigger {
	#emitter = new Subject<void>();
	emitted$ = this.#emitter.asObservable()
		.pipe(
			switchMap(() => {
				return timer(2000).pipe(map(() => false), startWith(true), take(2));
			}),
			startWith(false),
		);

	public emit(): void {
		this.#emitter.next();
	}
}
