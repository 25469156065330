import { Component, inject } from '@angular/core';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { faPoll } from '@fortawesome/free-solid-svg-icons';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { ElasticLinkReportCodelist } from '@app/shared/model/types/codelists/elastic-link-report-codelist';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-navigation',
	templateUrl: './app-navigation.component.html',
	styleUrls: ['./app-navigation.component.scss'],
})
export class AppNavigationComponent {
	#codelistRepository = inject(CodelistRepositoryService);


	readonly svgIconNames = SvgIconName;
	readonly faIcons = {
		elasticLinkReports: faPoll,
	};

	protected elasticLinkReports = toSignal(
		this.#codelistRepository.get<ElasticLinkReportCodelist[]>(NonUrlCodelistName.ElasticLinkReport),
	);
}
