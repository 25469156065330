import { Injectable } from '@angular/core';
import { GaResolverService } from '@app/shared/ga-components/services/ga-resolver.service';
import { isObject } from '@app/shared/general-helper';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment, MomentInput } from 'moment';
import * as momentTimezone from 'moment-timezone';

@Injectable({
	providedIn: 'root'
})
export class DateTimeHelperService {

	static readonly iso8601DatePattern = '^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$';
	static readonly datePattern = '^\\d{4}-\\d\\d-\\d\\d$';

	constructor(private gaResolver: GaResolverService) {
	}

	/**
	 * Builds dateTime string from date and time inputs. Date and time might be in Date, Moment, string, ... formats
	 *
	 * @param date
	 * @param time
	 * @param transformToUTC
	 * @param isoFormat
	 */
	public buildDateTimeString(
		date: MomentInput = null,
		time: MomentInput = null,
		transformToUTC = false,
		isoFormat = false
	): string | null {
		let dateObject = date ? moment(date) : null;
		let timeObject = time ? moment(time) : null;

		if (transformToUTC) {
			dateObject = date ? moment.utc(date) : null;
			timeObject = time ? moment.utc(time) : null;
		}

		if (dateObject) {
			if (timeObject) {
				dateObject.set({
					hours: timeObject.hours(),
					minutes: timeObject.minutes(),
					seconds: timeObject.seconds()
				})
				return isoFormat ? dateObject.format() : dateObject.format('MM/DD/YYYY hh:mm:ss A')
			}

			return isoFormat ? dateObject.format() : dateObject.format('MM/DD/YYYY');
		}

		return null;
	}

	public formatDateTime(dateTimeObject: Moment = null, isoFormat = false): string | null {
		if (dateTimeObject) {
			return isoFormat ? dateTimeObject.format() : dateTimeObject.format('Y-MM-DD HH:mm:ss');
		}
		return null;
	}

	public formatDatesInFormPayload(formValues) {
		_.forIn(formValues, (value, key) => {
			if (_.isDate(value)) {
				formValues[key] = moment(value).format('YYYY-MM-DD');
			} else if (isObject(value)) {
				this.formatDatesInFormPayload(value);
			}
		});

		return formValues;
	}

	public getDateTimeBasedOnTimezone(value, timezone, momentFormat = 'MM/DD/YYYY hh:mm A'): string {
		if (!value) {
			return null;
		}
		const zone = timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
		return momentTimezone(value).tz(zone).format(momentFormat);
	}

	public getValueAsDate(value): Date {
		return value ? moment(value).toDate() : moment().toDate();
	}

	public findDatesAndFormat(html: string) {
		const dateRegex = new RegExp(DateTimeHelperService.datePattern);
		const iso8601DateRegex = new RegExp(DateTimeHelperService.iso8601DatePattern);

		const elements = _.split(html, ' ');
		const formattedElements = _.map(elements, (element: string) => {
			let formatter;
			if (dateRegex.test(element)) {
				formatter = this.gaResolver.resolveFormatter({ type: 'date' });
			} else if (iso8601DateRegex.test(element)) {
				formatter = this.gaResolver.resolveFormatter({ type: 'datetime' });
			} else {
				formatter = (value) => value;
			}

			return formatter(element);
		});

		return _.join(formattedElements, ' ');
	}
}
