<div #container class="ag-ng-select-editor" tabindex="0">
	<ng-select [formControl]="selectControl"
			   class="base-input auto-height"
			   style="max-width: 200px;"
			   [clearable]="clearable"
			   [virtualScroll]="true"
			   [isOpen]="true"
			   [items]="items | async"
			   [searchFn]="searchingHelper[ngSearchFunction]">
		<ng-template ng-label-tmp let-item="item">
			<span *ngFor="let key of ngTemplateKeys" style="margin-right: 3px;">
				{{ item[key] }}
			</span>
		</ng-template>
		<ng-template ng-option-tmp let-item="item" let-item$="item$">
			<div class="base-flex base-flex--align-center">
				<span *ngFor="let key of ngTemplateKeys" style="margin-right: 3px;">
					{{ item[key] }}
				</span>
				<ng-container *ngIf="additionalTemplateKeys && additionalTemplateKeys.length > 0">
					(<span *ngFor="let key of additionalTemplateKeys"
						   style="font-size: 10px; color: var(--primary);">{{ lodashGet(item, key, '') }}</span>)
				</ng-container>
			</div>
		</ng-template>
	</ng-select>
</div>
