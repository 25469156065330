import { Injectable } from '@angular/core';
import { NoteServiceAction, NoteServiceResponse } from '@app/shared/model/types/note-service-model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NoteService { //todo: get rid of this service

	private tempNewNote: any = null;
	private tempNewBulkNotes: any = [];
	private noteEmitter$ = new Subject<NoteServiceResponse>();

	public getNewNote() {
		return this.tempNewNote;
	}

	public setNewNote(newNote, action: NoteServiceAction) {
		this.tempNewNote = newNote;
		this.emitNote(newNote, action);
	}

	public getNewBulkNotes() {
		return this.tempNewBulkNotes;
	}

	public setNewBulkNotes(newNotes) {
		this.tempNewBulkNotes = newNotes;
	}

	public emitNote(note, action: NoteServiceAction): void {
		this.noteEmitter$.next({ note, action });
	}

	public getNotes(): Observable<any> {
		return this.noteEmitter$.asObservable();
	}

}
