import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ResponsiveService } from '@app/core/startup/responsive.service';
import { fadeInOut } from '@app/shared/animations/animations';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { FileTypeReverseMapping } from '@app/shared/model/constants/file-type';
import { SvgIconName } from '@app/shared/model/constants/svg-icon-name';
import { DocumentFile } from '@app/shared/model/types/file';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { DownloadLinkConfig } from '@app/widgets/download-link/download-link-config.model';
import { DownloadLinkComponent } from '@app/widgets/download-link/download-link.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
	selector: 'documents-list-file',
	templateUrl: './documents-list-file.component.html',
	styleUrls: ['./documents-list-file.component.scss'],
	animations: [fadeInOut],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		AsyncPipe,
		ReactiveFormsModule,
		SVGIconComponent,
		DatePipe,
		FontAwesomeModule,
		TooltipModule,
		LetDirective,
		DownloadLinkComponent
	]
})
export class DocumentsListFileComponent implements OnInit {
	@Input() file: DocumentFile;
	@Input() fileType: string;
	@Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

	showConfirmationDialog$ = new BehaviorSubject(false);
	showRenameDialog$ = new BehaviorSubject(false);

	downloadLink$: Subject<DownloadLinkConfig> = new Subject<DownloadLinkConfig>();
	fileNameControl: FormControl<string>;

	readonly svgIconNames = SvgIconName;
	readonly faIcons = {
		pencilAlt: faPencilAlt,
	};

	constructor(
		private apiLink: GaApiLink,
		public responsiveService: ResponsiveService
	) {
	}

	public ngOnInit(): void {
		this.fileNameControl = new FormControl(this.file.originalTitle);
	}

	public onClickRemove(): void {
		this.showConfirmationDialog$.next(true);
	}

	public triggerRenameDialog(): void {
		this.showRenameDialog$.next(true);
	}

	public closeRenameDialog(): void {
		this.fileNameControl.setValue(this.file.originalTitle);
		this.showRenameDialog$.next(false);
	}

	public removeFile(): void {
		this.onRemove.emit();
		this.showConfirmationDialog$.next(false);
	}

	public cancel(): void {
		this.showConfirmationDialog$.next(false);
	}

	public renameFile(): void {
		this.apiLink.update(
			FileTypeReverseMapping[this.fileType ?? this.file.file_type],
			this.file,
			{ originalTitle: this.fileNameControl.value },
			[]
		).subscribe(() => {
			this.file.originalTitle = this.fileNameControl.value;
			this.showRenameDialog$.next(false);
		});
	}

	public downloadFile(): void {
		this.downloadLink$.next({
			url: `/api/aws/download/${this.file.id}`,
		});
	}
}
