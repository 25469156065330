/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketMessageType } from '@app/core/web-socket/web-socket-message-type';
import { WebSocketService } from '@app/core/web-socket/web-socket.service';
import { CodelistListItem, CodelistMeta } from '@app/shared/model/types/codelist-meta';
import { catchError, filter, from, Observable, of, switchMap, tap } from 'rxjs';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { CodelistSupportedEntities, getDBEntity, urlCodelistDb } from '@app/database/urlCodelistDb';
import { codelistDb } from '@app/database/codelistDb';
import { liveQuery } from 'dexie';
import moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class CodelistRepositoryService {

	#isLoadingCodelist: Record<string, boolean> = {};

	constructor(
		private http: HttpClient,
		private webSocketService: WebSocketService,
	) {
		this.listenToWebsocket();
	}

	public fetchCodelistsList(): Observable<CodelistListItem[]> {
		return this.http.get<CodelistListItem[]>('/api/code-lists/_list').pipe(
			catchError((errorResponse: unknown) => this.handleCodelistsApiError<CodelistListItem>(errorResponse)),
		);
	}

	public fetchCodelistWithMetadata(slug: string): Observable<CodelistMeta> {
		const params = { params: { meta: '1', activeOnly: '0' } };
		return this.http.get<CodelistMeta>(`/api/code-lists/${slug}`, params).pipe(
			catchError((errorResponse: unknown) => this.handleSingleCodelistApiError<CodelistMeta>(errorResponse)),
		);
	}

	public get<T extends Codelist[]>(codelistClass: string): Observable<T> {
		const entityTable = codelistDb.table<T[number]>(codelistClass);
		const timestampEntry = codelistDb.timeStamps.get(codelistClass);
		const handleRequestedData$ = this.http.get<T>(`/api/v2/code-lists/${this.urlize(codelistClass)}`)
			.pipe(
				tap(async (codelistData: T) => {
					await entityTable.bulkPut(codelistData);
					this.#isLoadingCodelist[codelistClass] = false;
				}),
				switchMap(() => {
					return from(liveQuery(() => entityTable.toArray()));
				})
			);

		return from(Promise.all([entityTable.count(), timestampEntry])).pipe(
			switchMap(([itemsCount, timestamp]) => {
				if ((itemsCount === 0 || this.isCodelistStale(timestamp.timestamp)) && !this.#isLoadingCodelist[codelistClass]) {
					this.#isLoadingCodelist[codelistClass] = true;
					return handleRequestedData$;
				}
				return from(liveQuery(() => entityTable.toArray()));
			}),
			catchError((errorResponse: unknown) => this.handleCodelistsApiError(errorResponse) as Observable<any>)
		);
	}

	public getByUrl<T extends CodelistSupportedEntities[]>(url: string): Observable<T> {
		const entityTable = getDBEntity(url);
		const timestampEntry = urlCodelistDb.timeStamps.get(entityTable.name);
		const handleRequestedData$ = this.http.get<T>(`/api/${url}`).pipe(
			tap(async (codelistData) => {
				await entityTable.bulkPut(codelistData);
				this.#isLoadingCodelist[url] = false;
			}),
			switchMap(() => {
				return from(liveQuery(() => entityTable.toArray()));
			})
		);
		return from(Promise.all([entityTable.count(), timestampEntry])).pipe(
			switchMap(([itemsCount, timestamp]) => {
				if ((itemsCount === 0 || this.isCodelistStale(timestamp.timestamp)) && !this.#isLoadingCodelist[url]) {
					this.#isLoadingCodelist[url] = true;
					return handleRequestedData$;
				}
				return from(liveQuery(() => entityTable.toArray()));
			}),
			catchError((errorResponse: unknown) => this.handleCodelistsApiError(errorResponse) as Observable<any>)
		)
	}

	private isCodelistStale(timestamp: Date): boolean {
		return  moment(timestamp).add(4, 'h').isBefore(moment());
	}

	private urlize(value) {
		return value.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
	}

	private deUrlize(value: string) {
		return value.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
	}

	private listenToWebsocket() {
		// FIXME: resolve multiple tabs - new message triggers update on all tabs. One update should be enough!
		// https://dev.to/arielgueta/keeping-browser-tabs-in-sync-using-localstorage-angular-akita-p39
		this.webSocketService
			.getConnection()
			.pipe(
				filter((message) => message.type == WebSocketMessageType.UpdateUrlCodelistCache),
				switchMap(async ({ data }) => {
					const entityTable = getDBEntity(data.url);
					await entityTable.clear();
					await entityTable.bulkAdd(JSON.parse(data.payload));
					return data.url;
				}),
			).subscribe((url) => {
			console.log(`* Received command to update URL codelist: ${url}`);
		});
		this.webSocketService
			.getConnection()
			.pipe(
				filter((message) => message.type == WebSocketMessageType.UpdateCodelistCache),
				switchMap(async ({data}) => {
					const entityTable = codelistDb.table(this.deUrlize(data.codelist));
					await entityTable.clear();
					await entityTable.bulkAdd(JSON.parse(data.payload));
					return data.codelist;
				}),
			).subscribe((codelistClass) => {
			console.log(`* Received command to update codelist: ${codelistClass}`);
		});
	}

	private handleCodelistsApiError<T>(errorResponse): Observable<T[]> {
		console.error('* CodeList could not be loaded.', errorResponse);
		return of<T[]>([] as T[]);
	}

	private handleSingleCodelistApiError<T>(errorResponse): Observable<T> {
		console.error('* CodeList could not be loaded.', errorResponse);
		return of<T>({} as T);
	}

	public clear(): void {
		this.#isLoadingCodelist = {};
		urlCodelistDb.tables.forEach((table) => table.clear());
		codelistDb.tables.forEach((table) => table.clear());
	}
}
