import { Injectable } from '@angular/core';
import { TokenStorageService } from '@app/core/auth/token-storage.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';

@Injectable({
	providedIn: 'root'
})
/* eslint-disable no-console */
export class WebSocketService {

	private socket: Socket;
	private readonly connection: Observable<any>;

	constructor(tokenStorage: TokenStorageService) {
		console.log(environment.websocketApiUrl);

		const token = tokenStorage.getAuthToken();

		this.socket = io(environment.websocketApiUrl, {
			transports: ['websocket'],
			query: {
				authToken: token
			}
		});

		this.socket.on('connect', () => {
			console.log('Connection to WS server established');
		});

		this.socket.on('connect_error', (error) => {
			console.log('Error while connecting to WS server:', error);
		});

		this.socket.on('connect_timeout', (error) => {
			console.log('Connection timeout to WS server', error);
		});

		this.socket.on('reconnect_attempt', (attempt) => {
			console.log(`Reconnect attempt: ${attempt}`);
			this.socket.io.opts.transports = ['polling', 'websocket'];
		});

		const observable = new Observable(observer => {
			this.socket.on('message', (message) => {
				observer.next(message.data);
			});

			this.socket.on('error', (error) => {
				console.log('Connection error:', error);
			});

			this.socket.on('disconnect', () => {
				console.log('Disconnected from WS server');
			});

			return () => {
				this.socket.disconnect();
			};
		});

		this.connection = observable.pipe(shareReplay({ bufferSize: 1, refCount: true }));
	}

	public reconnect(userToken: string) {
		this.disconnect();

		this.socket.io.opts.query = { authToken: userToken };
		this.socket.connect();
	}

	public disconnect() {
		this.socket.disconnect();
	}

	public getConnection() {
		return this.connection;
	}
}
