<public-view header="New password" subtitle="Please setup your new password">
	<form class="new-password__form" role="form" data-cy="newPassword-form" [formGroup]="newPasswordForm" (ngSubmit)="setPassword()" autocomplete="off" #form="ngForm">
		<div class="new-password__form__input-wrapper" [ngClass]="{'error' : newPassword.invalid}">
			<password-validation-input [highlightError]="trigger.emitted$ | async">
				<password-visibility-switch>
					<input placeholder="New password" type="password" passwordShow formControlName="newPassword" data-cy="newPassword" autocomplete="off">
				</password-visibility-switch>
			</password-validation-input>
		</div>
		<div class="new-password__form__input-wrapper" [ngClass]="{'error' : newPasswordForm.errors?.passwordMismatch && confirmPassword.dirty, 'highlight-error': trigger.emitted$ | async}">
			<password-visibility-switch>
				<input placeholder="Confirm password" type="password" passwordShow name="confirmPassword" formControlName="confirmPassword" id="confirmPassword" data-cy="confirmPassword-input" autocomplete="off">
			</password-visibility-switch>
			<span class="flex gap-2 mt-2 px-2" [ngClass]="!!newPasswordForm.errors?.passwordMismatch ? 'error' : 'success'" *ngIf="confirmPassword.dirty || form.submitted">
				<fa-icon [icon]="newPasswordForm.errors?.passwordMismatch ? faIcons.failed : faIcons.success" size="lg" />
				password {{ newPasswordForm.errors?.passwordMismatch ? 'doesn\'t match' : 'matches' }}
			</span>
		</div>
		<button class="new-password__form__button animated" data-cy="submit-btn" (animationend)="shake = false" [ngClass]="{'shake': shake}" type="submit">
			Save new password
		</button>
		<div *ngIf="generalViolationErrors().length > 0" class="pt-2 flex flex-col gap-2 general-errors">
			<span *ngFor="let violation of generalViolationErrors()" class="flex gap-2 mt-2 px-2 text-danger font-bold">
				<fa-icon [icon]="faIcons.failed" size="lg" />
				{{ violation.message }}
			</span>
		</div>
	</form>
</public-view>
