import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

type HttpError = {
	status: number,
	error: {
		message: string
	}
}

interface BaseError {
	detail: string;
	title: string;
	type: string;
	violations: Violation[];
}

interface Violation {
	propertyPath: string;
	message: string;
	code: number
}

export const IGNORE_API_ERRORS = new HttpContextToken(() => false);

@Injectable({
	providedIn: 'root'
})
export class ApiErrorInterceptor implements HttpInterceptor {

	constructor(
		private flashMessage: FlashMessageService,
		private authService: AuthService,
		private identityProvider: IdentityProviderService
	) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (!this.identityProvider.getIdentity().isAuthenticated || req.context.get(IGNORE_API_ERRORS)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(catchError((httpError: unknown) => {

			if (this.isHttpError(httpError)) {

				if (httpError.status === 401) {
					this.authService.logout();
				} else if (httpError.status === 403) {
					const msg = httpError.error.message || 'You are not allowed to access this section.';
					this.flashMessage.showMessage(FlashMessageType.Danger, msg);
				} else if (httpError.status === 422 || httpError.status === 400) {
					const customError = (httpError.error as unknown as BaseError)
					if(customError?.detail) {
						this.flashMessage.showMessage(FlashMessageType.Danger, customError.detail);
					} else if (customError?.violations?.length > 0) {
						const msg = customError.violations.map((violation) => {
							return violation.propertyPath
								? `Field "${violation.propertyPath}" - ${violation.message}`
								: violation.message;
							}).join('\n');
						this.flashMessage.showMessage(FlashMessageType.Danger, msg, {timeout: 10000});
					} else {
						this.flashMessage.showMessage(FlashMessageType.Danger, 'An error has occurred.');
					}
				} else if (httpError.status === 500) {
					this.flashMessage.showMessage(FlashMessageType.Danger,
						'A server error occurred. Please report this issue to the technical team.');
				} else if (httpError.status === -1) {
					//when request is cancelled, this caused alert to show
				} else if (httpError.status === 404) {
					this.flashMessage.showMessage(FlashMessageType.Danger, 'Requested data not found.');
				}
			}
			return throwError(() => httpError);
		}));

	}

	private isHttpError(httpError: unknown): httpError is HttpError {
		return Object.getOwnPropertyNames(httpError).includes('status');
	}
}
