import {AfterContentInit, Component, ContentChild, Input} from '@angular/core';
import {NgControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {JsonPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PasswordStrengthValidator } from '@app/widgets/password-validation-input/password-strength.validator';

@Component({
	selector: 'password-validation-input',
	templateUrl: 'password-validation-input.component.html',
	styleUrls: ['password-validation-input.component.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		NgIf,
		FontAwesomeModule,
		NgClass,
		JsonPipe,
		NgForOf
	]
})
export class PasswordValidationInputComponent implements AfterContentInit {
	@Input() highlightError = false;

	@ContentChild(NgControl) inputControl: NgControl;

	readonly minLength = 8;

	readonly faIcons = {
		success: faCheckCircle,
		failed: faTimesCircle,
	}

	public ngAfterContentInit(): void {
		this.inputControl.control.addValidators([
			Validators.required,
			Validators.minLength(this.minLength),
			PasswordStrengthValidator.strong
		]);
		this.inputControl.control.updateValueAndValidity();
	}
}
