<div class="wrapper" [ngClass]="{ 'error-highlight': highlightError }">
	<ng-content />
	<section class="px-2 mt-2 gap-2">
		<span class="flex gap-2" [ngClass]="inputControl.errors?.required || !!inputControl.errors?.minlength ? 'error' : 'success'">
			<fa-icon [icon]="inputControl.errors?.required || !!inputControl.errors?.minlength ? faIcons.failed : faIcons.success" size="lg" />
			{{ minLength }} characters minimum
		</span>
		<span class="flex gap-2" [ngClass]="!!inputControl.errors?.passwordStrength?.missingUpper ? 'error' : 'success'">
			<fa-icon [icon]="!!inputControl.errors?.passwordStrength?.missingUpper ? faIcons.failed : faIcons.success" size="lg" />
			at least one uppercase
		</span>
		<span class="flex gap-2" [ngClass]="!!inputControl.errors?.passwordStrength?.missingLower ? 'error' : 'success'">
			<fa-icon [icon]="!!inputControl.errors?.passwordStrength?.missingLower ? faIcons.failed : faIcons.success" size="lg" />
			at least one lowercase
		</span>
		<span class="flex gap-2" [ngClass]="!!inputControl.errors?.passwordStrength?.missingNumber ? 'error' : 'success'">
			<fa-icon [icon]="!!inputControl.errors?.passwordStrength?.missingNumber ? faIcons.failed : faIcons.success" size="lg" />
			at least one number
		</span>
		<span class="flex gap-2 validation-error" *ngFor="let violation of (inputControl.errors?.violations ?? [])">
			<fa-icon [icon]="faIcons.failed" size="lg" />
			{{ violation.template }}
		</span>
	</section>
</div>
