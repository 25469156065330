import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { IdentityProviderService } from '@app/core/auth/identity-provider.service';
import { GoogleAnalyticsService } from '@app/core/googleanalytics/googleanalytics.service';
import { ResponsiveService } from '@app/core/startup/responsive.service';
import { FeatureFlagService } from '@app/core/unleash/feature-flag.service';
import { LocalStorageKeys } from '@app/shared/model/constants/local-storage-keys';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { setTheme } from 'ngx-bootstrap/utils';
import { filter, Subject, takeUntil } from 'rxjs';
import {PrimeNGConfig} from 'primeng/api';

declare const gtag;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
	private destroy$ = new Subject<void>();

	constructor(
		public identityProvider: IdentityProviderService,
		public responsiveService: ResponsiveService,
		private localService: BsLocaleService,
		private featureFlagService: FeatureFlagService,
		private router: Router,
		private googleAnalyticsService: GoogleAnalyticsService,
		private authService: AuthService,
		primeNGConfig: PrimeNGConfig,
	) {
		setTheme('bs5');
		const enUsLocale = {
			...enGbLocale, longDateFormat: {
				LTS: 'h:mm:ss A',
				LT: 'h:mm A',
				L: 'MM/DD/YYYY',
				LL: 'MMMM D, YYYY',
				LLL: 'MMMM D, YYYY h:mm A',
				LLLL: 'dddd, MMMM D, YYYY h:mm A'
			}
		};
		defineLocale('en-us', enUsLocale);
		this.localService.use('en-us');
		moment.updateLocale('en', {
			week: {
				dow: 1,
				doy: 4
			}
		});
		primeNGConfig.setTranslation({
			firstDayOfWeek: 1,
		})
		this.featureFlagService.start();
		this.getGoogleAnalyticsMsrmntId();
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			takeUntil(this.destroy$)
		).subscribe((event: NavigationEnd) => {
			this.googleAnalyticsService.trackEvent(
				'page_view',
				{
					'page_path': event.urlAfterRedirects,
					'userId': this.authService.getCurrentUser().id,
				}
			)
		});
		this.router.events.pipe(
			filter(e => e instanceof NavigationError && e.error?.name === 'ChunkLoadError'),
			takeUntil(this.destroy$)
		).subscribe((e: NavigationError) => {
			localStorage.setItem(LocalStorageKeys.LAST_VISITED_PAGE, e.url);
			window.location.reload();
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.featureFlagService.stop();
	}

	public getGoogleAnalyticsMsrmntId() {
		//Add custom element of script with following attributes
		const customGtagScriptEle: HTMLScriptElement = document.createElement('script');
		customGtagScriptEle.async = true;
		customGtagScriptEle.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsMsrmntId;
		document.head.prepend(customGtagScriptEle);
		gtag('config', environment.googleAnalyticsMsrmntId, { send_page_view: false });
	}
}
