import Dexie, { Table } from 'dexie';
import { Codelist } from '@app/shared/model/types/codelists/codelist';
import { CaseStatus } from '@app/shared/model/types/codelists/case-status';
import { CaseTypes } from '@app/shared/model/types/codelists/case-types';
import { CollectabilityStatusCodelist } from '@app/shared/model/types/codelists/collectability-status.codelist';
import { CreditFacility } from '@app/shared/model/types/codelists/credit-facility';
import { DefendantPolicyLimit } from '@app/shared/model/types/codelists/defendant-policy-limit';
import { DepartmentCodelist } from '@app/shared/model/types/codelists/department';
import { CodelistWithDescription } from '@app/shared/model/types/codelists/codelist-with-description';
import { DoctorSpecialization } from '@app/shared/model/types/codelists/doctor-specialization';
import { DoctorAndMedicalFacilityTier } from '@app/shared/model/types/codelists/doctor-and-medical-facility-tier';
import { ElasticLinkReportCodelist } from '@app/shared/model/types/codelists/elastic-link-report-codelist';
import { ExternalLeadSource } from '@app/shared/model/types/codelists/external-lead-source';
import { FirmType } from '@app/shared/model/types/codelists/firm-type';
import { FundingLabel } from '@app/shared/model/types/codelists/funding-label';
import { FundingType } from '@app/shared/model/types/funding-type';
import { FundsDeliveryOption } from '@app/shared/model/types/codelists/funds-delivery-option';
import { HolidayCodelist } from '@app/shared/model/types/codelists/holiday';
import { InjuryType } from '@app/shared/model/types/codelists/injury-type';
import { LawFirmTier } from '@app/shared/model/types/codelists/law-firm-tier';
import { LawFirmTitle } from '@app/shared/model/types/codelists/law-firm-title';
import { MedicalFacilityType } from '@app/shared/model/types/codelists/medical-facility-type';
import { MedicalFundingSpecialty } from '@app/shared/model/types/codelists/medical-funding-specialty';
import { MedicalProcedureType } from '@app/shared/model/types/codelists/medical-procedure-type';
import { MedicalProcedureCategory } from '@app/shared/model/types/codelists/medical-procedure-category';
import { MedicalProviderType } from '@app/shared/model/types/codelists/medical-provider-type';
import { MedicalTreatment } from '@app/shared/model/types/codelists/medical-treatment';
import { PricingModel } from '@app/shared/model/types/codelists/pricing-model';
import { SalesActivityType } from '@app/shared/model/types/codelists/sales-activity-type';
import { ServicingGroup } from '@app/shared/model/types/codelists/servicing-group';
import { SmsTemplateCodelist } from '@app/shared/model/types/codelists/sms-template-codelist';
import { TodoCategoryCodelist } from '@app/shared/model/types/codelists/todo-category-codelist';
import { TodoLabel } from '@app/shared/model/types/codelists/todo-label';
import { UsState } from '@app/shared/model/types/codelists/us-state';
import { ValueRange } from '@app/shared/model/types/codelists/value-range';
import { TimezoneItem } from '@app/shared/model/types/codelists/timezone';
import { EffectiveRate } from '@app/shared/model/types/codelists/effective-rate';
import { DoctorLocationTier } from '@app/shared/model/types/codelists/doctor-location-tier';
import { MedicalAuditStage } from '@app/shared/model/types/codelists/medical-audit-stage';
import { MedicalAuditSource } from '@app/shared/model/types/codelists/medical-audit-source';
import { MedicalOpportunityStatement } from '@app/shared/model/types/codelists/medical-opportunity-statement';
import { updateTimestampMiddleware } from '@app/database/updateTimestampMiddleware';

export class CodelistDb extends Dexie {
    archivedReasonType: Table<Codelist, number>;
    appointmentResolutionState: Table<Codelist, number>;
    buyoutFunder: Table<Codelist, number>;
    callSubjectType: Table<Codelist, number>;
    carAccidentType: Table<Codelist, number>;
    caseStatus: Table<CaseStatus, number>;
    caseType: Table<CaseTypes, number>;
    collectabilityStatus: Table<CollectabilityStatusCodelist, number>;
    courtCounty: Table<Codelist, number>;
    creditFacility: Table<CreditFacility, number>;
    defendantsLimit: Table<DefendantPolicyLimit, number>;
    department: Table<DepartmentCodelist, number>;
    departmentPosition: Table<Codelist, number>;
    doctorLawFirmRelationshipType: Table<CodelistWithDescription, number>;
    doctorSpecialization: Table<DoctorSpecialization, number>;
    doctorAndMedicalFacilityTier: Table<DoctorAndMedicalFacilityTier, number>;
    elasticLink: Table<ElasticLinkReportCodelist, number>;
    externalLeadSource: Table<ExternalLeadSource, number>;
    firmType: Table<FirmType, number>;
    fundingAction: Table<Codelist, number>;
    fundingLabel: Table<FundingLabel, number>;
    fundingType: Table<FundingType, number>;
    fundsDeliveryOption: Table<FundsDeliveryOption, number>;
    geographicLocation: Table<Codelist, number>;
    hardInjuryType: Table<Codelist, number>;
    holiday: Table<HolidayCodelist, number>;
    identifiedWeakness: Table<Codelist, number>;
    injuryType: Table<InjuryType, number>;
    lawFirmLeadSource: Table<Codelist, number>;
    lawFirmTier: Table<LawFirmTier, number>;
    lawFirmTitle: Table<LawFirmTitle, number>;
    medicalBuyoutFunder: Table<CodelistWithDescription, number>;
    medicalFacilityContactTitle: Table<Codelist, number>;
    medicalFacilityType: Table<MedicalFacilityType, number>;
    medicalFundingSpecialty: Table<MedicalFundingSpecialty, number>;
    medicalProcedureType: Table<MedicalProcedureType, number>;
    medicalProcedureCategory: Table<MedicalProcedureCategory, number>;
    medicalProviderType: Table<MedicalProviderType, number>;
    medicalTimeline: Table<Codelist, number>;
    medicalTreatment: Table<MedicalTreatment, number>;
    nextAdvanceInfoDoc: Table<Codelist, number>;
    premisesType: Table<Codelist, number>;
    pricingModel: Table<PricingModel, number>;
    salesActivityType: Table<SalesActivityType, number>;
    salesStage: Table<CodelistWithDescription, number>;
    servicingGroup: Table<ServicingGroup, number>;
    shortPayReason: Table<Codelist, number>;
    smsTemplate: Table<SmsTemplateCodelist, number>;
    subterritory: Table<Codelist, number>;
    todoCategory: Table<TodoCategoryCodelist, number>;
    todoLabel: Table<TodoLabel, number>;
    usState: Table<UsState, number>;
    valueRange: Table<ValueRange, number>;
    timezone: Table<TimezoneItem, number>;
    medicalReportStatus: Table<Codelist, number>;
    billingStatus: Table<Codelist, number>;
    qcStatus: Table<Codelist, number>;
    loaType: Table<Codelist, number>;
    lienLetterStatus: Table<Codelist, number>;
    lawFirmType: Table<Codelist, number>;
    doctorReferralSource: Table<Codelist, number>;
    doctorRequires: Table<Codelist, number>;
    doctorLien: Table<Codelist, number>;
    quarterback: Table<Codelist, number>;
    implantsInvoice: Table<Codelist, number>;
    effectiveRate: Table<EffectiveRate, number>;
    interactionRating: Table<Codelist, number>;
    gender: Table<Codelist, number>;
    doctorLocationTier: Table<DoctorLocationTier, number>;
    doctorContactTypes: Table<Codelist, number>;
    medicalAuditStage: Table<MedicalAuditStage, number>;
    medicalAuditSource: Table<MedicalAuditSource, number>;
    medicalOpportunityStatement: Table<MedicalOpportunityStatement, number>;
    typicalCaseCostPricingType: Table<Codelist, number>;
    treatmentRecommendation: Table<Codelist, number>;
    rejectionReason: Table<Codelist, number>;
    schedulingStage: Table<Codelist, number>;
    contactOption: Table<Codelist, number>;
    contactResult: Table<Codelist, number>;
    addFundsDeploymentReason: Table<Codelist, number>;
    fundingOrigin: Table<Codelist, number>;

	timeStamps: Table<{ codelist: string, timestamp: Date }, string>;

    constructor() {
        super('codelists');
        this.version(1).stores({
            archivedReasonType: 'id',
            appointmentResolutionState: 'id',
            buyoutFunder: 'id',
            callSubjectType: 'id',
            carAccidentType: 'id',
            caseStatus: 'id',
            caseType: 'id',
            collectabilityStatus: 'id',
            courtCounty: 'id',
            creditFacility: 'id',
            defendantsLimit: 'id',
            department: 'id',
            departmentPosition: 'id',
            doctorLawFirmRelationshipType: 'id',
            doctorSpecialization: 'id',
            doctorAndMedicalFacilityTier: 'id',
            elasticLink: 'id',
            externalLeadSource: 'id',
            firmType: 'id',
            fundingAction: 'id',
            fundingLabel: 'id',
            fundingType: 'id',
            fundsDeliveryOption: 'id',
            geographicLocation: 'id',
            hardInjuryType: 'id',
            holiday: 'id',
            identifiedWeakness: 'id',
            injuryType: 'id',
            lawFirmLeadSource: 'id',
            lawFirmTier: 'id',
            lawFirmTitle: 'id',
            medicalBuyoutFunder: 'id',
            medicalFacilityContactTitle: 'id',
            medicalFacilityType: 'id',
            medicalFundingSpecialty: 'id',
            medicalProcedureType: 'id',
            medicalProcedureCategory: 'id',
            medicalProviderType: 'id',
            medicalTimeline: 'id',
            medicalTreatment: 'id',
            nextAdvanceInfoDoc: 'id',
            premisesType: 'id',
            pricingModel: 'id',
            salesActivityType: 'id',
            salesStage: 'id',
            servicingGroup: 'id',
            shortPayReason: 'id',
            smsTemplate: 'id',
            subterritory: 'id',
            todoCategory: 'id',
            todoLabel: 'id',
            usState: 'id',
            valueRange: 'id',
            timezone: 'id',
            medicalReportStatus: 'id',
            billingStatus: 'id',
            qcStatus: 'id',
            loaType: 'id',
            lienLetterStatus: 'id',
            lawFirmType: 'id',
            doctorReferralSource: 'id',
            doctorRequires: 'id',
            doctorLien: 'id',
            quarterback: 'id',
            implantsInvoice: 'id',
            effectiveRate: 'id',
            interactionRating: 'id',
            gender: 'id',
            doctorLocationTier: 'id',
            doctorContactTypes: 'id',
            medicalAuditStage: 'id',
            medicalAuditSource: 'id',
            medicalOpportunityStatement: 'id',
            typicalCaseCostPricingType: 'id',
            treatmentRecommendation: 'id',
            rejectionReason: 'id',
            schedulingStage: 'id',
            contactOption: 'id',
            contactResult: 'id',
            addFundsDeploymentReason: 'id',
            fundingOrigin: 'id',
			timeStamps: 'codelist',
        });

		this.version(2).stores({
			archivedReasonType: 'id, title, orderIndex',
			appointmentResolutionState: 'id, title, orderIndex',
			buyoutFunder: 'id, title, orderIndex',
			callSubjectType: 'id, title, orderIndex',
			carAccidentType: 'id, title, orderIndex',
			caseStatus: 'id, title, orderIndex',
			caseType: 'id, title, orderIndex',
			collectabilityStatus: 'id, title, orderIndex',
			courtCounty: 'id, title, orderIndex',
			creditFacility: 'id, title, orderIndex',
			defendantsLimit: 'id, title, orderIndex',
			department: 'id, title, orderIndex',
			departmentPosition: 'id, title, orderIndex',
			doctorLawFirmRelationshipType: 'id, title, orderIndex',
			doctorSpecialization: 'id, title, orderIndex',
			doctorAndMedicalFacilityTier: 'id, title, orderIndex',
			elasticLink: 'id, title, orderIndex',
			externalLeadSource: 'id, title, orderIndex',
			firmType: 'id, title, orderIndex',
			fundingAction: 'id, title, orderIndex',
			fundingLabel: 'id, title, orderIndex',
			fundingType: 'id, title, orderIndex',
			fundsDeliveryOption: 'id, title, orderIndex',
			geographicLocation: 'id, title, orderIndex',
			hardInjuryType: 'id, title, orderIndex',
			holiday: 'id, title, orderIndex',
			identifiedWeakness: 'id, title, orderIndex',
			injuryType: 'id, title, orderIndex',
			lawFirmLeadSource: 'id, title, orderIndex',
			lawFirmTier: 'id, title, orderIndex',
			lawFirmTitle: 'id, title, orderIndex',
			medicalBuyoutFunder: 'id, title, orderIndex',
			medicalFacilityContactTitle: 'id, title, orderIndex',
			medicalFacilityType: 'id, title, orderIndex',
			medicalFundingSpecialty: 'id, title, orderIndex',
			medicalProcedureType: 'id, title, orderIndex',
			medicalProcedureCategory: 'id, title, orderIndex',
			medicalProviderType: 'id, title, orderIndex',
			medicalTimeline: 'id, title, orderIndex',
			medicalTreatment: 'id, title, orderIndex',
			nextAdvanceInfoDoc: 'id, title, orderIndex',
			premisesType: 'id, title, orderIndex',
			pricingModel: 'id, title, orderIndex',
			salesActivityType: 'id, title, orderIndex',
			salesStage: 'id, title, orderIndex',
			servicingGroup: 'id, title, orderIndex',
			shortPayReason: 'id, title, orderIndex',
			smsTemplate: 'id, title, orderIndex',
			subterritory: 'id, title, orderIndex',
			todoCategory: 'id, title, orderIndex',
			todoLabel: 'id, title, orderIndex',
			usState: 'id, title, orderIndex',
			valueRange: 'id, title, orderIndex',
			timezone: 'id, title, orderIndex',
			medicalReportStatus: 'id, title, orderIndex',
			billingStatus: 'id, title, orderIndex',
			qcStatus: 'id, title, orderIndex',
			loaType: 'id, title, orderIndex',
			lienLetterStatus: 'id, title, orderIndex',
			lawFirmType: 'id, title, orderIndex',
			doctorReferralSource: 'id, title, orderIndex',
			doctorRequires: 'id, title, orderIndex',
			doctorLien: 'id, title, orderIndex',
			quarterback: 'id, title, orderIndex',
			implantsInvoice: 'id, title, orderIndex',
			effectiveRate: 'id, title, orderIndex',
			interactionRating: 'id, title, orderIndex',
			gender: 'id, title, orderIndex',
			doctorLocationTier: 'id, title, orderIndex',
			doctorContactTypes: 'id, title, orderIndex',
			medicalAuditStage: 'id, title, orderIndex',
			medicalAuditSource: 'id, title, orderIndex',
			medicalOpportunityStatement: 'id, title, orderIndex',
			typicalCaseCostPricingType: 'id, title, orderIndex',
			treatmentRecommendation: 'id, title, orderIndex',
			rejectionReason: 'id, title, orderIndex',
			schedulingStage: 'id, title, orderIndex',
			contactOption: 'id, title, orderIndex',
			contactResult: 'id, title, orderIndex',
			addFundsDeploymentReason: 'id, title, orderIndex',
			fundingOrigin: 'id, title, orderIndex',
			timeStamps: 'codelist',
		});
    }
}

export const codelistDb = new CodelistDb();

codelistDb.use(updateTimestampMiddleware);
