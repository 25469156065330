import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { AgGridCustomLoaderComponent } from '@app/shared/ag-grid-utils/ag-grid-custom-loader.component';
import { trimEnd } from '@app/shared/general-helper';
import { UserAccount } from '@app/shared/model/types/user-account';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class AgGridUtilsService {

	static readonly EXCEL_STYLES = [
		{
			id: 'header',
			interior: {
				color: '#16a085',
				pattern: 'Solid'
			},
			font: { color: '#ffffff' }
		},

		{
			id: 'headerGroup',
			font: {
				bold: true,
			},
		},

		{
			id: 'gold-header',
			interior: {
				color: '#E4AB11',
				pattern: 'Solid',
			},
			font: { color: '#ffffff' }
		},

		{
			id: 'green-cell',
			interior: {
				color: '#16a085',
				pattern: 'Solid'
			},
		},

		{
			id: 'indent-1',
			alignment: { indent: 1 }
		},
		{
			id: 'indent-2',
			alignment: { indent: 2 }
		},
		{
			id: 'indent-3',
			alignment: { indent: 3 }
		},
		{
			id: 'numberType',
			numberFormat: {
				format: '0'
			}
		},
		{
			id: 'decimalNumberType',
			numberFormat: {
				format: '0.00'
			}
		},
		{
			id: 'currencyFormat',
			numberFormat: {
				format: '$#,##0.00',
			},
		},
		{
			id: 'percentage',
			numberFormat: {
				format: '#,##0.00%'
			}
		},
		{
			id: 'dateType',
			dataType: 'DateTime',
			numberFormat: {
				format: 'mm/dd/yyyy'
			}
		},
		{
			id: 'dateTimeType',
			dataType: 'DateTime',
			numberFormat: {
				format: 'mm/dd/yyyy h:mm AM/PM'
			}
		},
		{
			id: 'booleanType',
			dataType: 'boolean'
		},
		{
			id: 'main-header',
			font: { bold: true }
		},
		{
			id: 'stringFormat',
			dataType: 'string'
		}
	];

	constructor(
		private router: Router,
		private codelistService: CodelistRepositoryService
	) {
	}

	public getGridOptions(gridOptionsToMerge?: GridOptions): GridOptions {
		return _.merge({
			statusBar: this.getStatusBarSettings(),
			sideBar: true,
			suppressCsvExport: true,
			excelStyles: AgGridUtilsService.EXCEL_STYLES,
			components: {
				customLoadingOverlay: AgGridCustomLoaderComponent
			},
			loadingOverlayComponent: 'customLoadingOverlay',
			loadingOverlayComponentParams: { loadingMessage: 'Loading Data...' },
			defaultColDef: {
				filter: true,
				floatingFilter: true,
				sortable: true,
				resizable: true,
				enablePivot: true,
				enableRowGroup: true,
				enableValue: true
			}
		}, gridOptionsToMerge);
	}

	/* renderer */
	public lawFirmLinkRenderer(lawFirmId: string | number, lawFirmName: string): string {
		if (!lawFirmId || !lawFirmName) {
			return '';
		}

		const href = this.router.createUrlTree(['/law-firms', lawFirmId]).toString();

		return `<a href="${href}" target="_blank">${lawFirmName}</a>`;
	}

	public applicantLinkRenderer(applicantId: string | number, applicantName: string): string {
		if (!applicantId || !applicantName) {
			return '';
		}

		const href = this.router.createUrlTree(['/applicants', applicantId]).toString();

		return `<a href="${href}" target="_blank">${applicantName}</a>`;
	}

	public fundingLinkRenderer(fundingInternalId: string, fundingId: string | number, applicantId: string | number): string {
		if (!fundingInternalId || !applicantId) {
			return '';
		}

		const href = this.router.createUrlTree(['/applicants', applicantId], {queryParams: { funding: fundingId }}).toString();

		return `<a href="${href}" target="_blank">${fundingInternalId}</a>`;
	}

	public doctorLinkRenderer(doctorId: string | number, doctorName: string): string {
		if (!doctorId || !doctorName) {
			return '';
		}

		const href = this.router.createUrlTree(['/doctors', doctorId]).toString();

		return `<a href="${href}" target="_blank">${doctorName}</a>`;
	}

	public medicalFacilityLinkRenderer(medicalFacilityId: string | number, medicalFacilityName: string): string {
		if (!medicalFacilityId || !medicalFacilityName) {
			return '';
		}

		const href = this.router.createUrlTree(['/medical-facilities', medicalFacilityId]).toString();

		return `<a href="${href}" target="_blank">${medicalFacilityName}</a>`;
	}

	public attorneyLinkRenderer(lawFirmId: string | number, staffId: string | number, staffName: string): string {
		if (!lawFirmId || !staffId || !staffName) {
			return '';
		}

		const href = this.router.createUrlTree(['/law-firms', lawFirmId, 'staff', staffId]).toString();

		return `<a href="${href}" target="_blank">${staffName}</a>`;
	}

	public caseManagerLinkRenderer(lawFirmId: string | number, staffId: string | number, staffName: string): string {
		if (!lawFirmId || !staffId || !staffName) {
			return '';
		}

		const href = this.router.createUrlTree(['/law-firms', lawFirmId, 'staff', staffId]).toString();

		return `<a href="${href}" target="_blank">${staffName}</a>`;
	}

	/* value formatters */
	public getFullName(user: Pick<UserAccount, 'firstName' | 'lastName'>, defaultValue: string): string {
		if (user?.firstName || user?.lastName) {
			return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
		}
		return defaultValue;
	}

	public excelDateFormatter(date, format = 'YYYY-MM-DD'): string {
		return date ? moment(date).format(format) : '';
	}

	public documentStatusFormatter(value): string {
		return value.replace(/_/g, ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	}

	public getArrayOfStringsByFiledName(data: any[], fields: string | string[], separator = ''): string[] {
		const resultArray = data.map((item) => {
			if (Array.isArray(fields)) {
				return this.composeStringFromFields(item, fields, separator);
			} else {
				return item[fields];
			}
		});

		resultArray.unshift(null); // blank option
		return resultArray;
	}

	public renderListOfLabels(values: any[] | string, isActivityType?: boolean): string {
		if (!Array.isArray(values)) {
			return values;
		}
		if (values.length === 0 && isActivityType) {
			values = [{title: 'Note', colorHex: 'var(--text)'}]
		}

		let labels = '';
		values.forEach(value => {
			labels += `<span class="badge" style="background-color: ${value.colorHex};
						color: #fff; display: inline-block; margin-right: 3px;">${value.title}</span>`;
		});

		return labels;
	}

	private composeStringFromFields(item: any, fields: string[], separator = ''): string {
		const composedString = fields.reduce((result, attr) => {
			return result += `${_.get(item, attr, '')?.trim() ?? ''}${separator} `;
		}, '');
		return trimEnd(composedString, `${separator} `)
	}

	public stringComparatorByName(a: { fistName: string, lastName: string }, b: { fistName: string, lastName: string }): number {
		const getName = user => `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
		return this.stringComparator(getName(a), getName(b));
	}

	public stringComparator(a, b): number {
		if (!a && !b) {
			return 0;
		}
		if (!a) {
			return -1;
		}
		if (!b) {
			return 1;
		}

		return a.trim().localeCompare(b.trim());
	}

	public generalProcessCellCallback(params): string {
		return params.value !== null && typeof (params.value) === 'object' ? params.column.colDef.valueFormatter(params) : params.value;
	}

	public getStatusBarSettings(supportsSelecting = false): { statusPanels: { statusPanel: string, align: string }[] } {
		let additionalPanels = [];

		if (supportsSelecting) {
			additionalPanels = [
				{
					statusPanel: 'agSelectedRowCountComponent',
					align: 'left'
				}
			];
		}

		return {
			statusPanels: [
				{
					statusPanel: 'agTotalAndFilteredRowCountComponent',
					align: 'left'
				},
				...additionalPanels
			]
		};
	}
}
