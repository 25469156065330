import { Component, ContentChild, signal } from '@angular/core';
import { PasswordShowDirective } from '@app/shared/directives/password-show.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'password-visibility-switch',
	templateUrl: 'password-visibility-switch.component.html',
	standalone: true,
	imports: [
		FontAwesomeModule
	]
})
export class PasswordVisibilitySwitchComponent {
	@ContentChild(PasswordShowDirective) passwordShow: PasswordShowDirective;

	readonly faIcons = {
		showPassword: faEye,
		hidePassword: faEyeSlash,
	}

	passwordVisible = signal<boolean>(false);

	public toggleVisibility(): void {
		this.passwordVisible.update((val) => !val);
		this.passwordShow.toggle();
	}
}
