import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[passwordShow]',
	standalone: true,
})
export class PasswordShowDirective {
	constructor(private el: ElementRef) {
	}

	public toggle(): void {
		if (this.el.nativeElement.type === 'text') {
			this.hide();
		} else {
			this.show();
		}
	}

	public show(): void {
		this.el.nativeElement.type = 'text';
	}

	public hide(): void {
		this.el.nativeElement.type = 'password';
	}
}
