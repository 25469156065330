import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { SalesActivityReport } from '@app/reports/sales-activity-report/sales-activity-report.model';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { SalesActivityType } from '@app/shared/model/types/codelists/sales-activity-type';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class SalesActivityReportService {

	constructor(private http: HttpClient,
				private codelistRepository: CodelistRepositoryService) {
	}

	public fetchData(dateRange: Array<Date>): Observable<SalesActivityReport[]> {
		let from = '';
		let to = '';
		if (dateRange && dateRange.length > 0) {
			const startDate = new Date(dateRange[0]);
			startDate.setHours(0,0,0,0);
			const endDate = new Date(dateRange[1])
			endDate.setHours(23,59,59,999);
			const fromDate = new Date(startDate.getTime() + (startDate.getTimezoneOffset() * 60000));
			const toDate = new Date(endDate.getTime() + (endDate.getTimezoneOffset() * 60000));
			from = moment.utc(fromDate).format('Y-MM-DD HH:mm:ss');
			to = moment.utc(toDate).format('Y-MM-DD HH:mm:ss');
		}

		return this.http.get<SalesActivityReport[]>('/api/statistics/sales-activity-report', {
			params: {
				'from': from,
				'to': to,
			}
		});
	}

	public getSalesActivityTypes(): Observable<SalesActivityType[]> {
		return this.codelistRepository.get(NonUrlCodelistName.SalesActivityType);
	}
}
