<div class="new-modal">
	<div class="new-modal__header">
		<h3>{{ fundingNote?.id ? 'Edit' : 'Add' }} Funding Note</h3>
	</div>
	<form [formGroup]="form">
		<div class="base-input__wrapper">
			<div class="label-wrapper">
				<label class="base-label">Funding</label>
				<form-validation-label [control]="form.controls.funding"></form-validation-label>
			</div>
			<ng-select [items]="fundings$ | async"
					   [searchFn]="searchingHelper.searchFunding"
					   formControlName="funding"
					   class="base-input"
					   data-cy="fundings-select"
					   placeholder="Please select funding">
				<ng-template ng-label-tmp let-item="item">
						<span>{{ item?.internalId }} | {{ item?.fundingType?.title }}
							| {{ item | fundingAmount | currency : 'USD' : 'symbol' : '1.2-2' }}</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item">
						<span>{{ item?.internalId }} | {{ item?.fundingType?.title }}
							| {{ item | fundingAmount | currency : 'USD' : 'symbol' : '1.2-2' }}</span>
				</ng-template>
			</ng-select>
		</div>
		<div class="base-input__wrapper">
			<div class="label-wrapper">
				<label class="base-label" for="dateToFollowUp">Date To Follow Up</label>
			</div>
			<input type="text"
				   class="base-input"
				   id="dateToFollowUp"
				   data-cy="followUpDate-datepicker"
				   formControlName="dateToFollowUp"
				   [bsConfig]="bsConfig"
				   bsDatepicker
				   readonly>
		</div>
		<div>
			<div class="label-wrapper">
				<label class="base-label">Content</label>
				<form-validation-label [control]="form.controls.content"></form-validation-label>
			</div>
			<md-editor formControlName="content"
					   [mdAutoFocus]="active"
					   [height]="'200px'"
					   [options]="contentEditorOptions"
					   data-cy="content-element">
			</md-editor>
		</div>

		<ng-container *ngrxLet="isFundingMedical$; let isFundingMedical">
			<label *ngIf="!isFundingMedical" class="base-checkbox__wrapper">
				<input class="base-checkbox__wrapper__input"
					   type="checkbox"
					   formControlName="rocknetVisible"/>
				<span class="base-checkbox__wrapper__checkmark"></span>
				Share with Portal
			</label>

			<div *ngIf="isFundingMedical" class="base-input__wrapper">
				<div class="label-wrapper">
					<label class="base-label">Reach Out Target</label>
					<form-validation-label [control]="form.controls.medicalReachOutTarget"></form-validation-label>
				</div>
				<ng-select class="base-input"
						   data-cy="medicalReachOutTarget-select"
						   formControlName="medicalReachOutTarget"
						   [searchable]="false">
					<ng-option *ngFor="let reachOutTarget of medicalReachOutPossibleTargets"
							   [value]="reachOutTarget.value"> {{ reachOutTarget.label }}
					</ng-option>
				</ng-select>
			</div>
		</ng-container>

		<label class="base-label">
			IR Rating
		</label>
		<title-codelist-select
			dataCy="interactionRating"
			placeholder="IR Rating"
			[path]="interactionRatingCodeList"
			[inputControl]="form.controls.interactionRating"></title-codelist-select>

		<label class="base-checkbox__wrapper mt-10">
			<input class="base-checkbox__wrapper__input" type="checkbox" [formControl]="complianceControl">
			<span class="base-checkbox__wrapper__checkmark"></span>
			Compliance Note
		</label>
	</form>

	<div class="new-modal__footer">
		<button class="base-button base-button--cancel" data-cy="cancel-btn"
				(click)="closeModal(bsModalReason.Cancel)">Cancel
		</button>
		<app-loading-button
			dataCy="submit-btn"
			[disabled]="form.invalid"
			(onBtnClick)="checkPortalSharing($event)"
		>Save
		</app-loading-button>
	</div>
	<div *ngIf="showPortalShareWarning$ | async" class="loading-panel" @fadeInOut>
		<div class="loading-panel__message">
			<div class="loading-panel__message__text">
				<h4>Are you sure you want to share this note with the law firm via RockNet?</h4>
			</div>
			<div>
				<button class="base-button base-button--cancel" (click)="hidePortalSharingWarning()">Cancel</button>
				<app-loading-button
					dataCy="submit-btn"
					[disabled]="form.invalid"
					(onBtnClick)="saveNote($event)"
				>Yes
				</app-loading-button>
			</div>
		</div>
	</div>
</div>
