import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyHelperService } from '@app/shared/model/currency-helper.service';

@Directive({
	selector: 'input[currency][formControlName],input[currency][formControl]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CurrencyInputDirective),
			multi: true,
		}
	]
})
export class CurrencyInputDirective implements ControlValueAccessor {
	private onChange: (value) => void;
	private onTouched: () => void;

	constructor(private currencyHelper: CurrencyHelperService,
				private elementRef: ElementRef,
				private renderer: Renderer2) {
	}

	@HostListener('input', ['$event.target.value'])
	public onInput(value: string): void {
		this.onValueChange(value);
	}

	@HostListener('blur', ['$event.target.value'])
	public onBlur(value: string): void {
		if (!value.includes('.') && value !== '') {
			this.onValueChange(`${value}.00`);
		}
		this.onTouched();
	}

	@HostListener('focus', ['$event.target.value'])
	public onFocus(value: string): void {
		if (value.includes('.00')) {
			this.onValueChange(value.substring(0, value.indexOf('.')));
		}
	}

	private onValueChange(value: string): void {
		const parsedValue = this.currencyHelper.parseCurrency(value)?.toFixed(2);
		this.writeValue(value);
		if (this.onChange) {
			this.onChange(parsedValue);
		}
	}

	public writeValue(val: string | number): void {
		const valueFromInput = val?.toString();

		if (val == null || !(new RegExp(/[0-9]/).test(valueFromInput))) {
			this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
			return;
		}

		const lengthBeforeFormatting = valueFromInput.length;

		let caretPos = this.elementRef.nativeElement.selectionStart;

		const decimalPointPosition = valueFromInput.indexOf('.');
		let wholeNumber = valueFromInput;
		let numberAfterDecimalPoint;

		if (decimalPointPosition != -1) {
			wholeNumber = valueFromInput.substring(0, decimalPointPosition);
			//get only first two decimal numbers
			numberAfterDecimalPoint = valueFromInput.substring(decimalPointPosition + 1, decimalPointPosition + 3);
		}

		wholeNumber = this.currencyHelper.formatCurrency(wholeNumber, '1.0');

		if (wholeNumber === null) {
			this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
			return;
		}

		let formattedValue;
		if (decimalPointPosition == -1) {
			formattedValue = wholeNumber;
		} else {
			formattedValue = `${wholeNumber}.${numberAfterDecimalPoint}`;
		}

		const lengthOfFormattedValue = formattedValue.length;
		if (lengthBeforeFormatting != caretPos) {
			caretPos = lengthOfFormattedValue - lengthBeforeFormatting + caretPos;
		} else {
			caretPos += 1;
		}

		this.renderer.setProperty(this.elementRef.nativeElement, 'value', formattedValue);
		this.elementRef.nativeElement.setSelectionRange(caretPos, caretPos);
	}

	public registerOnChange(fn: any): void {
		this.onChange = (value) => {
			fn(value == '' ? null : parseFloat(value));
		};
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
	}
}
