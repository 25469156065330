export enum NonUrlCodelistName {
	ArchivedReasonType = 'archivedReasonType',
	AppointmentResolutionState = 'appointmentResolutionState',
	BuyoutFunder = 'buyoutFunder',
	CallSubjectType = 'callSubjectType',
	CarAccidentType = 'carAccidentType',
	CaseStatus = 'caseStatus',
	CaseType = 'caseType',
	CollectabilityStatus = 'collectabilityStatus',
	ColumnHeaderDescription = 'columnHeaderDescription',
	CourtCounty = 'courtCounty',
	CreditFacility = 'creditFacility',
	DealSize = 'dealSize',
	DefendantsLimit = 'defendantsLimit',
	Department = 'department',
	DepartmentPosition = 'departmentPosition',
	DoctorLawFirmRelationshipType = 'doctorLawFirmRelationshipType',
	DoctorSpecialization = 'doctorSpecialization',
	DoctorAndMedicalFacilityTier = 'doctorAndMedicalFacilityTier',
	ElasticLinkReport = 'elasticLink',
	ExternalLeadSource = 'externalLeadSource',
	FirmType = 'firmType',
	FundingAction = 'fundingAction',
	FundingLabel = 'fundingLabel',
	FundingType = 'fundingType',
	FundsDeliveryOption = 'fundsDeliveryOption',
	GeographicLocation = 'geographicLocation',
	HardInjuryType = 'hardInjuryType',
	Holiday = 'holiday',
	IdentifiedWeakness = 'identifiedWeakness',
	InjuryType = 'injuryType',
	LawFirmLeadSource = 'lawFirmLeadSource',
	LawFirmTier = 'lawFirmTier',
	LawFirmTitle = 'lawFirmTitle',
	MedicalBuyoutFunder = 'medicalBuyoutFunder',
	MedicalFacilityContactTitle = 'medicalFacilityContactTitle',
	MedicalFacilityType = 'medicalFacilityType',
	MedicalFundingSpecialty = 'medicalFundingSpecialty',
	MedicalProcedureType = 'medicalProcedureType',
	MedicalProcedureCategory = 'medicalProcedureCategory',
	MedicalProviderType = 'medicalProviderType',
	MedicalTimeline = 'medicalTimeline',
	MedicalTreatment = 'medicalTreatment',
	NextAdvanceInfoDoc = 'nextAdvanceInfoDoc',
	PremisesType = 'premisesType',
	PricingModel = 'pricingModel',
	SalesActivityType = 'salesActivityType',
	SalesStage = 'salesStage',
	ServicingGroup = 'servicingGroup',
	ShortPayReason = 'shortPayReason',
	SmsTemplate = 'smsTemplate',
	Subterritory = 'subterritory',
	TodoCategory = 'todoCategory',
	TodoLabel = 'todoLabel',
	UsState = 'usState',
	ValueRange = 'valueRange',
	Timezone = 'timezone',
	MedicalReportStatus = 'medicalReportStatus',
	BillingStatus = 'billingStatus',
	QcStatus = 'qcStatus',
	LOAType = 'loaType',
	LienLetterStatus = 'lienLetterStatus',
	LawFirmRelationType = 'lawFirmType',
	DoctorReferralSource = 'doctorReferralSource',
	DoctorRequires = 'doctorRequires',
	DoctorLien = 'doctorLien',
	Quarterback = 'quarterback',
	ImplantsInvoice = 'implantsInvoice',
	EffectiveRate = 'effectiveRate',
	InteractionRating = 'interactionRating',
	Genders = 'gender',
	DoctorLocationTier = 'doctorLocationTier',
	DoctorContactTypes = 'doctorContactType',
	MedicalAuditStage = 'medicalAuditStage',
	MedicalAuditSource = 'medicalAuditSource',
	MedicalOpportunityStatement = 'medicalOpportunityStatement',
	TypicalCaseCostPricingType = 'typicalCaseCostPricingType',
	TreatmentRecommendation = 'treatmentRecommendation',
	RejectionReason = 'rejectionReason',
	SchedulingStage = 'schedulingStage',
	ContactOption = 'contactOption',
	ContactResult = 'contactResult',
	MedicalReachOutTarget = 'medicalReachOutTarget',
	AddFundsDeploymentReason = 'addFundsDeploymentReason',
	FundingOrigin = 'fundingOrigin'
}

export class NonUrlCodelistHelper {

	public static includes(codeListName: string) {
		return (<any>Object).values(NonUrlCodelistName).includes(codeListName);
	}
}
