import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';

type AceEditor = {
	focus: () => void
}

@Directive({
	selector: '[mdAutoFocus]',
	standalone: true,
})
export class MarkdownAutoFocusDirective implements AfterViewInit {
	#editor: AceEditor;

	@Input() set mdAutoFocus(_value: boolean) {
		this.#editor?.focus();
	}


	@HostListener('onEditorLoaded', ['$event'])
	public onEditorLoaded(editor: AceEditor) {
		this.#editor = editor;
		editor.focus();
	}

	public ngAfterViewInit() {
		this.#editor.focus();
	}
}
