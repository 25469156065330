import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridUtilsModule } from '@app/shared/ag-grid-utils/ag-grid-utils.module';
import { AvatarModule } from '@app/shared/avatar/avatar.module';
import { CodelistMultiselectComponent } from '@app/shared/codelist-multiselect/codelist-multiselect.component';
import { ConfirmationDialogModule } from '@app/shared/confirmation-dialog/confirmation-dialog.module';
import { CustomFormControlsModule } from '@app/shared/custom-form-controls/custom-form-controls.module';
import { DatePickerRangesService } from '@app/shared/daterange-picker/date-picker-ranges/date-picker-ranges.service';
import { DaterangePickerComponent } from '@app/shared/daterange-picker/daterange-picker.component';
import { DetailFundingsTableComponent } from '@app/shared/detail-fundings-table/detail-fundings-table.component';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { DoctorAddressBookComponent } from '@app/shared/doctor-addresses-book/doctor-address-book.component';
import { EditedByComponent } from '@app/shared/edited-by/edited-by.component';
import { FormValidationLabelComponent } from '@app/shared/form-validation-label/form-validation-label.component';
import { GaComponentsModule } from '@app/shared/ga-components/ga-components.module';
import { GooglePlacesAutocompleteComponent } from '@app/shared/google-places-autocomplete/google-places-autocomplete.component';
import { LoadingSpinnerComponent } from '@app/shared/loading-spinner/loading-spinner.component';
import { ModalsModule } from '@app/shared/modals/modals.module';
import { ModalsService } from '@app/shared/modals/modals.service';
import { ChartsHelperService } from '@app/shared/model/charts-helper.service';
import { DataHistoryLogRepositoryService } from '@app/shared/model/data-history-log-repository.service';
import { FundingHelperService } from '@app/shared/model/funding-helper.service';
import { StatisticsRepositoryService } from '@app/shared/model/statistics-repository.service';
import { OptionButtonComponent } from '@app/shared/option-button/option-button.component';
import { OverlayPanelComponent } from '@app/shared/overlay-panel/overlay-panel.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { ScrollToTopComponent } from '@app/shared/scroll-to-top/scroll-to-top.component';
import { SelectListComponent } from '@app/shared/select-list/select-list.component';
import { DocumentFilesService } from '@app/shared/services/document-files.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NoteService } from '@app/shared/services/note.service';
import { SVGIconComponent } from '@app/shared/svg-icon/svg-icon.component';
import { TierRatingComponent } from '@app/shared/tier-rating/tier-rating.component';
import { UserAccountBadgeComponent } from '@app/shared/user-account-badge/user-account-badge.component';
import { UnassignedAvatarComponent } from '@app/shared/user-filter/unassigned-avatar/unassigned-avatar';
import { UserFilterComponent } from '@app/shared/user-filter/user-filter.component';
import { DoctorAddressModule } from '@app/widgets/doctor-address/doctor-address.module';
import { DocumentsListFileComponent } from '@app/widgets/documents-list-file/documents-list-file.component';
import { DownloadLinkComponent } from '@app/widgets/download-link/download-link.component';
import { FileUploadModule } from '@app/widgets/file-upload/file-upload.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { NgChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MarkdownModule } from 'ngx-markdown';
import { MomentModule } from 'ngx-moment';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		ModalsModule,
		BsDatepickerModule,
		BsDropdownModule,
		TypeaheadModule,
		PipesModule,
		NgChartsModule,
		NgSelectModule,
		GaComponentsModule,
		ConfirmationDialogModule,
		FileUploadModule,
		TooltipModule,
		MarkdownModule.forChild(),
		AgGridUtilsModule,
		InfiniteScrollModule,
		FontAwesomeModule,
		DirectivesModule,
		AgGridModule,
		AvatarModule,
		SVGIconComponent,
		DoctorAddressModule,
		DetailFundingsTableComponent,
		MomentModule,
		DocumentsListFileComponent,
		DownloadLinkComponent,
		NgxGpAutocompleteModule,
		LoadingSpinnerComponent,
		FormValidationLabelComponent,
	],
	declarations: [
		OverlayPanelComponent,
		DaterangePickerComponent,
		OptionButtonComponent,
		TierRatingComponent,
		UserFilterComponent,
		DoctorAddressBookComponent,
		GooglePlacesAutocompleteComponent,
		UnassignedAvatarComponent,
		UserAccountBadgeComponent,
		CodelistMultiselectComponent,
		SelectListComponent,
		ScrollToTopComponent,
		EditedByComponent
	],
	exports: [
		AvatarModule,
		CustomFormControlsModule,
		OverlayPanelComponent,
		DaterangePickerComponent,
		PipesModule,
		GaComponentsModule,
		ConfirmationDialogModule,
		OptionButtonComponent,
		TierRatingComponent,
		LoadingSpinnerComponent,
		UserFilterComponent,
		DoctorAddressBookComponent,
		GooglePlacesAutocompleteComponent,
		UnassignedAvatarComponent,
		UserAccountBadgeComponent,
		CodelistMultiselectComponent,
		FontAwesomeModule,
		DirectivesModule,
		SVGIconComponent,
		SelectListComponent,
		MomentModule,
		FormValidationLabelComponent,
		DetailFundingsTableComponent,
		DocumentsListFileComponent,
		DownloadLinkComponent,
		ScrollToTopComponent,
		EditedByComponent
	],
	providers: [
		DatePipe,
		ModalsService,
		DataHistoryLogRepositoryService,
		StatisticsRepositoryService,
		DatePickerRangesService,
		FundingHelperService,
		ModalService,
		NoteService,
		ChartsHelperService,
		DocumentFilesService,
	],
})
export class SharedModule {
}
