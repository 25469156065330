import { Injectable } from '@angular/core';
import { ApprovalStatus } from '@app/shared/model/constants/approval-status';
import { Funding } from '@app/shared/model/types/funding';
import * as moment from 'moment';

@Injectable()
export class FundingHelperService {

	public isFunded(funding) {
		return funding?.approvalStatus == ApprovalStatus.Funded;
	}

	public isSettled(funding) {
		return funding?.approvalStatus == ApprovalStatus.Settled;
	}

	public isPreset(funding) {
		return !this.isMedical(funding);
	}

	public isMedical(funding) {
		return funding?.fundingType?.medical;
	}

	public isMedicalLien(funding): boolean {
		return this.isMedical(funding) && !this.isMri(funding) && !this.isCourtReporting(funding);
	}

	public isMedicalScheduling(funding): boolean {
		return this.isMedicalLien(funding) && this.isConsult(funding);
	}

	public isMri(funding) {
		return funding?.fundingType?.isMri;
	}

	public isCourtReporting(funding) {
		return funding?.fundingType?.isCourtReporting;
	}

	public isMF(funding) {
		return funding?.fundingType?.presetAndMedical;
	}

	public isPending(funding) {
		return funding?.approvalStatus == ApprovalStatus.Pending;
	}

	public isApproved(funding) {
		return funding?.approvalStatus == ApprovalStatus.Approved;
	}

	public isRejected(funding) {
		return funding?.approvalStatus == ApprovalStatus.Rejected;
	}

	public isConsult(funding) {
		return funding?.approvalStatus == ApprovalStatus.Consult;
	}

	public isAtLeastApproved(funding) {
		return this.isApproved(funding) || this.isFunded(funding) || this.isSettled(funding);
	}

	public isAtLeastFunded(funding) {
		return this.isFunded(funding) || this.isSettled(funding);
	}

	public isPreArchived(funding) {
		return funding?.preArchivedOn != null;
	}

	public isArchived(funding) {
		return funding?.archived || funding?.archivedOn;
	}

	public isExternalPortfolio(funding) {
		return funding?.isExternalPortfolio;
	}

	public isActive(funding) {
		return funding && !this.isPreArchived(funding) && !this.isArchived(funding);
	}

	public isSurgery(funding: Funding) {
		return funding?.medicalProcedureType?.category?.surgery;
	}

	public getFaceValueAsNumber(funding) {
		return parseFloat(funding?.currentAmountOwed || 0);
	}

	public isShortPay(funding) {
		return this.isSettled(funding) && funding?.settledAt < this.getFaceValueAsNumber(funding);
	}

	public getStringRepresentationOfFundingState(funding): string {
		switch (funding?.approvalStatus) {
			case ApprovalStatus.Pending:
				return 'Pending';
			case ApprovalStatus.Approved:
				return 'Approved';
			case ApprovalStatus.Funded:
				return 'Funded';
			case ApprovalStatus.Rejected:
				return 'Rejected';
			case ApprovalStatus.Settled:
				return 'Settled';
			case ApprovalStatus.Consult:
				return 'Consult';
			default:
				return '';
		}
	}

	public pendingToApprovedInterval(funding) {
		if ((funding?.statusChangeApproved || funding?.approvedOn) && funding?.createdOn && funding?.appliedOn) {
			const endDate = moment(funding.statusChangeApproved || funding.approvedOn);
			const created = moment(funding.createdOn);
			const applied = moment(funding.appliedOn);
			const startDate = created.isSame(applied, 'day') ? created : applied;

			return this.displayTimeInterval(startDate, endDate);
		}

		return '---';
	}

	public approvedToFundedInterval(funding) {
		if (!this.isAtLeastApproved(funding) || !(funding?.statusChangeApproved || funding?.approvedOn)) {
			return '---';
		}

		const endDate = moment(funding.statusChangeFunded || funding.fundedOn || new Date());
		const startDate = moment(funding.statusChangeApproved || funding.approvedOn);
		return this.displayTimeInterval(startDate, endDate);
	}

	private displayTimeInterval(startDate, endDate) {
		const diff = endDate.diff(startDate);
		const duration = moment.duration(diff);
		const sign = diff < 0 ? '-' : '';
		return `${sign} ${duration.days()} days, ${duration.hours()} hrs, ${duration.minutes()} min ${duration.seconds()} s`;
	}
}
