import { SalesDevelopmentRepCodelist } from '@app/shared/model/types/codelists/sales-development-reps-codelist.ts';
import Dexie, { Table } from 'dexie';
import { AttorneyCodelist } from '@app/shared/model/types/codelists/attorney-codelist';
import { CaseManagerCodelist } from '@app/shared/model/types/codelists/case-manager-codelist';
import { CptCodeCodelistItem } from '@app/shared/model/types/cpt-code';
import { CsrCodelist } from '@app/shared/model/types/codelists/csr-codelist';
import { DoctorCodelist } from '@app/shared/model/types/doctor';
import { EmployeeCodelist } from '@app/shared/model/types/codelists/employee-codelist';
import { FundingConsultantCodelist } from '@app/shared/model/types/codelists/funding-consultant-codelist';
import { FundingSchedulerCodelist } from '@app/shared/model/types/codelists/funding-scheduler-codelist';
import { InsuranceCompanyCodelist } from '@app/shared/model/types/codelists/insurance-company-codelist';
import { LawFirmCodelist } from '@app/shared/model/types/codelists/law-firm-codelist';
import { LawFirmManagerCodelist } from '@app/shared/model/types/codelists/law-firm-manager-codelist';
import { MedicalAeCodelist } from '@app/shared/model/types/codelists/medical-ae-codelist';
import { MedicalFacilityCodelist } from '@app/shared/model/types/medical-facility';
import { MriSchedulerCodelist } from '@app/shared/model/types/codelists/mri-scheduler-codelist';
import { NegotiatorCodelist } from '@app/shared/model/types/codelists/negotiator-codelist';
import { PracticeGroupCodelist } from '@app/shared/model/types/codelists/practice-group-codelist';
import { SalesPersonCodelist } from '@app/shared/model/types/codelists/sales-person-codelist';
import { SalesRepresentativeCodelist } from '@app/shared/model/types/codelists/sales-representative-codelist';
import { UnderwriterCodelist } from '@app/shared/model/types/codelists/underwriter-codelist';
import { UrlCodelistLink } from '@app/shared/model/constants/url-codelist';
import { updateTimestampMiddleware } from '@app/database/updateTimestampMiddleware';

export type CodelistSupportedEntities =
	| AttorneyCodelist
	| CaseManagerCodelist
	| CptCodeCodelistItem
	| CsrCodelist
	| DoctorCodelist
	| EmployeeCodelist
	| SalesDevelopmentRepCodelist
	| FundingConsultantCodelist
	| FundingSchedulerCodelist
	| InsuranceCompanyCodelist
	| LawFirmCodelist
	| LawFirmManagerCodelist
	| MedicalAeCodelist
	| MedicalFacilityCodelist
	| MriSchedulerCodelist
	| NegotiatorCodelist
	| PracticeGroupCodelist
	| SalesPersonCodelist
	| SalesRepresentativeCodelist
	| UnderwriterCodelist;

export class UrlCodelistDb extends Dexie {
	attorneys: Table<AttorneyCodelist, number>;
	caseManagers: Table<CaseManagerCodelist, number>;
	cptCodes: Table<CptCodeCodelistItem, number>;
	csr: Table<CsrCodelist, number>;
	doctors: Table<DoctorCodelist, number>;
	employees: Table<EmployeeCodelist, number>;
	salesDevelopmentReps: Table<SalesDevelopmentRepCodelist, number>;
	fundingConsultants: Table<FundingConsultantCodelist, number>;
	fundingSchedulers: Table<FundingSchedulerCodelist, number>;
	insuranceCompanies: Table<InsuranceCompanyCodelist, number>;
	lawFirms: Table<LawFirmCodelist, number>;
	lawFirmManagers: Table<LawFirmManagerCodelist, number>;
	medicalAEs: Table<MedicalAeCodelist, number>;
	medicalFacilities: Table<MedicalFacilityCodelist, number>;
	mriSchedulers: Table<MriSchedulerCodelist, number>;
	negotiators: Table<NegotiatorCodelist, number>;
	practiceGroups: Table<PracticeGroupCodelist, number>;
	salesPersons: Table<SalesPersonCodelist, number>;
	salesRepresentatives: Table<SalesRepresentativeCodelist, number>;
	underwriters: Table<UnderwriterCodelist, number>;

	timeStamps: Table<{ codelist: string, timestamp: Date }, string>;

	constructor() {
		super('urlCodelists');
		this.version(2).stores({
			attorneys: 'id',
			caseManagers: 'id',
			cptCodes: 'id',
			csr: 'id',
			doctors: 'id',
			employees: 'id',
			salesDevelopmentReps: 'id',
			fundingConsultants: 'id',
			fundingSchedulers: 'id',
			insuranceCompanies: 'id',
			lawFirms: 'id',
			lawFirmManagers: 'id',
			medicalAEs: 'id',
			medicalFacilities: 'id',
			mriSchedulers: 'id',
			negotiators: 'id',
			practiceGroups: 'id',
			salesPersons: 'id',
			salesRepresentatives: 'id',
			underwriters: 'id',
			timeStamps: 'codelist',
		});
	}
}

export const urlCodelistDb = new UrlCodelistDb();

urlCodelistDb.use(updateTimestampMiddleware);

export function getDBEntity<T extends CodelistSupportedEntities>(url: string): Table<T, number> {
	switch (url) {
		case UrlCodelistLink.Attorneys: return urlCodelistDb.attorneys as Table<T, number>;
		case UrlCodelistLink.CaseManagers: return urlCodelistDb.caseManagers as Table<T, number>;
		case UrlCodelistLink.CptCodes: return urlCodelistDb.cptCodes as Table<T, number>;
		case UrlCodelistLink.CustomerServiceRepresentatives: return urlCodelistDb.csr as Table<T, number>;
		case UrlCodelistLink.Doctors: return urlCodelistDb.doctors as Table<T, number>;
		case UrlCodelistLink.Employees: return urlCodelistDb.employees as Table<T, number>;
		case UrlCodelistLink.SalesDevelopmentReps: return urlCodelistDb.salesDevelopmentReps as Table<T, number>;
		case UrlCodelistLink.FundingConsultants: return urlCodelistDb.fundingConsultants as Table<T, number>;
		case UrlCodelistLink.FundingSchedulers: return urlCodelistDb.fundingSchedulers as Table<T, number>;
		case UrlCodelistLink.InsuranceCompanies: return urlCodelistDb.insuranceCompanies as Table<T, number>;
		case UrlCodelistLink.LawFirms: return urlCodelistDb.lawFirms as Table<T, number>;
		case UrlCodelistLink.LawFirmManagers: return urlCodelistDb.lawFirmManagers as Table<T, number>;
		case UrlCodelistLink.MedicalAEs: return urlCodelistDb.medicalAEs as Table<T, number>;
		case UrlCodelistLink.MedicalFacilities: return urlCodelistDb.medicalFacilities as Table<T, number>;
		case UrlCodelistLink.MriSchedulers: return urlCodelistDb.mriSchedulers as Table<T, number>;
		case UrlCodelistLink.Negotiators: return urlCodelistDb.negotiators as Table<T, number>;
		case UrlCodelistLink.PracticeGroups: return urlCodelistDb.practiceGroups as Table<T, number>;
		case UrlCodelistLink.SalesPersons: return urlCodelistDb.salesPersons as Table<T, number>;
		case UrlCodelistLink.SalesRepresentatives: return urlCodelistDb.salesRepresentatives as Table<T, number>;
		case UrlCodelistLink.Underwriters: return urlCodelistDb.underwriters as Table<T, number>;
	}
}
