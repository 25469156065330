import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgottenPasswordComponent } from '@app/public/forgotten-password/forgotten-password.component';
import { LoginComponent } from '@app/public/login/login.component';
import { NewPasswordComponent } from '@app/public/new-password/new-password.component';
import { PublicRoutingModule } from '@app/public/public-routing.module';
import { PublicViewComponent } from '@app/public/public-view.component';
import { SharedModule } from '@app/shared/shared.module';
import { PasswordValidationInputComponent } from '@app/widgets/password-validation-input/password-validation-input.component';
import { PasswordShowDirective } from '@app/shared/directives/password-show.directive';
import { PasswordVisibilitySwitchComponent } from '@app/widgets/password-visibility-switch/password-visibility-switch.component';

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		SharedModule,
		PublicRoutingModule,
		PasswordValidationInputComponent,
		PasswordShowDirective,
		PasswordVisibilitySwitchComponent
	],
	declarations: [
		LoginComponent,
		ForgottenPasswordComponent,
		PublicViewComponent,
		NewPasswordComponent
	],
})
export class PublicModule {}
