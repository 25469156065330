import {Component, Input} from '@angular/core';
import { CodelistSelectComponent } from '@app/shared/codelist-selects/codelist-select/codelist-select.component';

@Component({
	selector: 'title-codelist-select',
	templateUrl: './title-codelist-select.component.html'
})
export class TitleCodelistSelectComponent extends CodelistSelectComponent {
	@Input() clearable: boolean;
}
