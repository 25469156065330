import { FormControl, ValidationErrors } from '@angular/forms';

export class PasswordStrengthValidator {

	public static strong(control: FormControl): ValidationErrors | null {
		if (!control.value) {
			return { passwordStrength: { missingNumber: true, missingUpper: true, missingLower: true } };
		}
		const hasNumber = /\d/.test(control.value);
		const hasUpper = /[A-Z]/.test(control.value);
		const hasLower = /[a-z]/.test(control.value);
		const valid = hasNumber && hasUpper && hasLower;
		if (valid) {
			return null;
		}
		return { passwordStrength: { missingNumber: !hasNumber, missingUpper: !hasUpper, missingLower: !hasLower } };
	}
}
