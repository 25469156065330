import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { confirmPasswordValidator } from '@app/shared/validators/confirm-password.validator';
import TimeoutTrigger from '@app/shared/timeout-trigger';
import { RequestViolation } from '@app/shared/model/request-violation';

@Component({
	selector: 'new-password-form',
	templateUrl: './new-password.component.html',
	styleUrls: ['new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit, OnDestroy {

	shake = false;

	newPasswordForm = new FormGroup({
		newPassword: new FormControl(''),
		confirmPassword: new FormControl('', [Validators.required])
	}, { validators: confirmPasswordValidator });

	generalViolationErrors = signal<RequestViolation[]>([]);

	readonly faIcons = {
		success: faCheckCircle,
		failed: faTimesCircle,
	};

	protected trigger = new TimeoutTrigger();

	private destroy$ = new Subject<void>();
	private queryParamsToken: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private flashMessage: FlashMessageService,
		private router: Router) {
	}

	public ngOnInit(): void {
		this.queryParamsToken = this.activatedRoute.snapshot.queryParams?.token;
	}

	public setPassword(): void {
		this.shake = this.newPasswordForm.invalid;
		if (this.newPasswordForm.valid) {
			this.newPasswordForm.setErrors(null);
			const headers = new HttpHeaders().set('InterceptorSkipHeader', '');
			this.http.post(
				this.activatedRoute.snapshot.data?.setAsNew ? '/api/users/set-up-password' : '/api/reset-password',
				{
					password: this.newPasswordForm.value?.newPassword,
					token: this.queryParamsToken
				},
				{ headers })
				.subscribe({
					next: () => {
						this.flashMessage.showMessage(FlashMessageType.Success, 'New password has been saved.', { timeout: 10000 });
						this.router.navigate(['/login']);
				}, error: (errorResponse: HttpErrorResponse) => {
						if (errorResponse.error?.violations) {
							const newPasswordViolations = errorResponse.error.violations.filter(
								(violation) => violation.propertyPath === 'password'
							);
							if (newPasswordViolations.length > 0) {
								this.newPasswordForm.controls.newPassword.setErrors({ violations: newPasswordViolations });
							}
							const generalViolations = errorResponse.error.violations.filter((violation) => violation.propertyPath === null);
							this.generalViolationErrors.set(generalViolations);
						} else {
							console.error(errorResponse);
							this.flashMessage.showMessage(
								FlashMessageType.Danger, 'There was an error processing your request', {timeout: 10000}
							);
						}
				}});
		} else {
			this.trigger.emit();
		}
	}

	get newPassword(): AbstractControl | null {
		return this.newPasswordForm.get('newPassword');
	}

	get confirmPassword(): AbstractControl | null {
		return this.newPasswordForm.get('confirmPassword');
	}

	public ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
