<ng-container *ngrxLet="round$; let roundValue">
	<div class="ibox float-e-margins" *ngrxLet="currentMonth$; let currentMonth">
		<collapsible-item [isManagedOutside]="isCollapseManaged">
			<div header-left>
				<h4>
					<fa-icon [icon]="faIcons.calendar" class="m-r-sm"></fa-icon>
					Current Month
				</h4>
				<div class="flex flex-col">
					<span class="flex">Business Days So Far: &nbsp;<ghost-element
						[width]="ghostElementWidths.footerStatsHeader"><b>{{ currentMonth?.lastMonthFundings.businessDaysSoFar }}
						/ {{ currentMonth?.lastMonthFundings.businessDaysInMonth }}</b></ghost-element></span>
					<span class="flex"># Funded: &nbsp;<ghost-element
						[width]="ghostElementWidths.sectionHeader"><b>{{ currentMonth?.lastMonthFundings.dealsCount }}</b></ghost-element></span>
				</div>
			</div>
			<h1 header-right>
				<ghost-element width="25"
							   height="4">{{ currentMonth?.lastMonthFundings.dealsCount | number : '1.0-0' }}
				</ghost-element>
			</h1>
			<div class="flex toggle">
				<span>Use effective rates</span>
				<rp-switch (switched)="toggleFundingsData($event)"></rp-switch>
			</div>
			<div class="ibox-content">
				<ul style="padding: 0;">
					<li>
						<div class="progress-wrap">
							<span># Funded</span>
							<div class="progress progress-mini">
								<div class="progress-bar"
									 [ngStyle]="{'width.%' : currentMonth?.lastMonthFundings.dealsCountPer3MoAverage | number : '1.0-1'}"></div>
							</div>
							<small>last 3Mo moving average:
								<ghost-element [width]="ghostElementWidths.sectionSubHeading">
									<b>{{ currentMonth?.threeMonthsFundings.avgDealsCount | number : '1.0-0' }}</b>
								</ghost-element>
							</small>

							<small>daily average # fundings:
								<ghost-element [width]="ghostElementWidths.sectionSubHeading">
									<b>{{ currentMonth?.threeMonthsFundings.avgThreeMonthsCountPerDay | number : '1.0-0' }}</b>
								</ghost-element>
							</small>
						</div>
						<div class="progress-data">
							<h2 class="no-margins">
								<ghost-element [width]="ghostElementWidths.sectionHeader"
											   [height]="ghostElementHeights.h2">{{ currentMonth?.lastMonthFundings.dealsCount | number : '1.0-0' }}
									/ {{ currentMonth?.lastMonthFundings.avgFundingsPerDay | number : '1.0-0' }}
								</ghost-element>
							</h2>
							<span class="font-bold text-navy"><ghost-element
								[width]="ghostElementWidths.sectionPercentage">{{ currentMonth?.lastMonthFundings.dealsCountPer3MoAverage | number : '1.0-0' }}
								%</ghost-element></span>
						</div>
					</li>
					<li class="mt-2">
						<div class="d-flex gap-4 flex-wrap breakdowns">
							<div class="flex-1 d-flex justify-content-between">
								Medical Count:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthFundings?.medicalCount | number: '1.0-1' }}
											/ {{ currentMonth?.lastMonthFundings?.avgMedicalCountPerDay | number : '1.0-1' }}
									</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Plaintiff Count:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthFundings?.plaintiffCount | number: '1.0-1' }}
											/ {{ currentMonth?.lastMonthFundings?.avgPlaintiffCountPerDay | number : '1.0-1' }}
									</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Case Cost Count:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthFundings?.caseCostCount | number: '1.0-1' }}
											/ {{ currentMonth?.lastMonthFundings?.avgCaseCostCountPerDay | number: '1.0-1' }}
									</b>
								</ghost-element>
							</div>
						</div>
					</li>
					<hr>
					<li>
						<div class="progress-wrap">
							<span>$ Funded</span>
							<div class="progress progress-mini">
								<div class="progress-bar"
									 [ngStyle]="{'width.%' : currentMonth?.dealsAmountPer3MoAverage  | number : '1.0-1'}"></div>
							</div>
							<small>Average Funding Size:
								<ghost-element [width]="ghostElementWidths.sectionSubHeading">
									<b>{{ currentMonth?.avgFundingSize | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</small>
							<small>last 3Mo moving average:
								<ghost-element [width]="ghostElementWidths.sectionSubHeading">
									<b>{{ currentMonth?.avgDealsAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</small>
						</div>
						<div class="progress-data">
							<h2 class="no-margins">
								<ghost-element [width]="ghostElementWidths.sectionHeader"
											   [height]="ghostElementHeights.h2">
									{{ currentMonth?.dealsAmount | currency : 'USD' : 'symbol' : '1.0-0' }}
								</ghost-element>
							</h2>
							<span class="font-bold text-navy"><ghost-element
								[width]="ghostElementWidths.sectionPercentage">{{ currentMonth?.dealsAmountPer3MoAverage  | number : '1.0-0' }}
								%</ghost-element></span>
						</div>
					</li>
					<li class="mt-2">
						<div class="d-flex gap-4 flex-wrap breakdowns">
							<div class="flex-1 d-flex justify-content-between">
								Medical Amount:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthFundings?.medicalAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Plaintiff Amount:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthFundings?.plaintiffAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Case Cost Amount:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthFundings?.caseCostAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</div>
						</div>
					</li>
					<hr>
					<li>
						<div class="breakdowns">
							<ghost-element width="60">
								<medical-purchases-table
									[medicalBreakdown]="currentMonth?.lastMonthFundings?.medicalBreakdown"></medical-purchases-table>
							</ghost-element>
						</div>
					</li>
					<hr>
					<li *aclIsAllowed="'dashboard.collected_stats'">
						<div class="progress-wrap">
							<span># Collected</span>
							<div class="progress progress-mini">
								<div class="progress-bar"
									 [ngStyle]="{'width.%' : currentMonth?.lastMonthCollected.dealsCountCollectedPer3MoAverage | number : '1.0-0'}"></div>
							</div>
							<small>last 3Mo moving average:
								<ghost-element [width]="ghostElementWidths.sectionSubHeading">
									<b>{{ currentMonth?.threeMonthsCollected.avgDealsCount | number : '1.0-0' }}</b>
								</ghost-element>
							</small>
						</div>
						<div class="progress-data">
							<h2 class="no-margins">
								<ghost-element [width]="ghostElementWidths.sectionHeader"
											   [height]="ghostElementHeights.h2">{{ currentMonth?.lastMonthCollected.dealsCount | number : '1.0-0' }}
								</ghost-element>
							</h2>
							<span class="stat-percent font-bold text-navy"><ghost-element
								[width]="ghostElementWidths.sectionPercentage">{{ currentMonth?.lastMonthCollected.dealsCountCollectedPer3MoAverage  | number : '1.0-0' }}
								%</ghost-element></span>
						</div>
					</li>
					<li class="mt-2">
						<div class="d-flex gap-4 flex-wrap breakdowns">
							<div class="flex-1 d-flex justify-content-between">
								Medical Count:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthCollected?.medicalCount | number: '1.0-1' }}</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Plaintiff Count:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthCollected?.plaintiffCount | number: '1.0-1' }}</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Case Cost Count:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthCollected?.caseCostCount | number: '1.0-1' }}</b>
								</ghost-element>
							</div>
						</div>
					</li>
					<hr>
					<li *aclIsAllowed="'dashboard.collected_stats'">
						<div class="progress-wrap">
							<span>$ Total Collected</span>
							<div class="progress progress-mini">
								<div class="progress-bar"
									 [ngStyle]="{ 'width.%' : currentMonth?.lastMonthCollected.dealsAmountCollectedPer3MoAverage | number : '1.0-0' }"></div>
							</div>
							<small>last 3Mo moving average:
								<ghost-element [width]="ghostElementWidths.sectionSubHeading">
									<b>{{ currentMonth?.threeMonthsCollected.avgDealsAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</small>
						</div>
						<div class="progress-data">
							<h2 class="no-margins">
								<ghost-element [width]="ghostElementWidths.sectionHeader"
											   [height]="ghostElementHeights.h2">{{ currentMonth?.lastMonthCollected.dealsAmount | currency : 'USD' : 'symbol' : '1.0-0' }}
								</ghost-element>
							</h2>
							<span class="font-bold text-navy"><ghost-element
								[width]="ghostElementWidths.sectionPercentage">{{ currentMonth?.lastMonthCollected.dealsAmountCollectedPer3MoAverage  | number : '1.0-0' }}
								%</ghost-element></span>
						</div>
						<div class="progress-info row">
							<div class="col-sm-6">
								<small>Principal:
									<ghost-element [width]="ghostElementWidths.sectionSubHeading">
										<b>{{ currentMonth?.lastMonthCollected.principal | currency : 'USD': 'symbol': roundValue ? '1.0-0' : '1.2-2' }}</b>
									</ghost-element>
								</small>
								<br>
								<small>Fee:
									<ghost-element [width]="ghostElementWidths.sectionSubHeading">
										<b>{{ currentMonth?.lastMonthCollected.fee | currency : 'USD': 'symbol': roundValue ? '1.0-0' : '1.2-2' }}</b>
									</ghost-element>
								</small>
								<br>
								<small>Principal Loss:
									<ghost-element [width]="ghostElementWidths.sectionSubHeading">
										<b>{{ currentMonth?.lastMonthCollected.principalLoss | currency : 'USD': 'symbol': roundValue ? '1.0-0' : '1.2-2' }}</b>
									</ghost-element>
								</small>
							</div>
							<div class="col-sm-6">
								<small>$ Collection Projected:
									<ghost-element [width]="ghostElementWidths.sectionSubHeading">
										<b>{{ currentMonth?.lastMonthCollected.collectionProjected | currency : 'USD': 'symbol': '1.2-2' }}</b>
									</ghost-element>
								</small>
								<br>
								<small>Settlement Multiple:
									<ghost-element [width]="ghostElementWidths.sectionSubHeading">
										<b>{{ currentMonth?.lastMonthCollected.settlementMultiple | number: '1.0-2' }}
											x</b></ghost-element>
								</small>
							</div>
						</div>
					</li>
					<li class="mt-2">
						<div class="d-flex gap-4 flex-wrap breakdowns">
							<div class="flex-1 d-flex justify-content-between">
								Medical Amount:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthCollected?.medicalAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Plaintiff Amount:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthCollected?.plaintiffAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</div>
							<div class="flex-1 d-flex justify-content-between">
								Case Cost Amount:
								<ghost-element width="4">
									<b>{{ currentMonth?.lastMonthCollected?.caseCostAmount | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</b>
								</ghost-element>
							</div>
						</div>
					</li>
				</ul>
				<hr>
				<div class="current-month-days">
					<table class="table-striped">
						<tr>
							<td>
								<small>Business Days In Month</small>
							</td>
							<td>
								<small>Avg # Fundings / Day</small>
							</td>
							<td>
								<small>Business Days So Far</small>

						</tr>
						<tr>
							<td>
								<h3>
									<ghost-element [width]="ghostElementWidths.footerStatsHeader"
												   [height]="ghostElementHeights.h3">{{ currentMonth?.lastMonthFundings.businessDaysInMonth }}
									</ghost-element>
								</h3>
							</td>
							<td>
								<h3>
									<ghost-element [width]="ghostElementWidths.footerStatsHeader"
												   [height]="ghostElementHeights.h3">{{ currentMonth?.lastMonthFundings.avgFundingsPerDay | number : '1.0-1' }}
									</ghost-element>
								</h3>
							</td>
							<td>
								<h3>
									<ghost-element [width]="ghostElementWidths.footerStatsHeader"
												   [height]="ghostElementHeights.h3">{{ currentMonth?.lastMonthFundings.businessDaysSoFar }}
									</ghost-element>
								</h3>
							</td>
						</tr>
						<tr>
							<td>
								<small>Avg $ Fundings / Day</small>
							</td>
							<td>
								<small># Fundings Projected</small>
							</td>
							<td>
								<small>$ Fundings Projected</small>
							</td>
						</tr>
						<tr>
							<td>
								<h3>
									<ghost-element [width]="ghostElementWidths.footerStatsHeader"
												   [height]="ghostElementHeights.h3">{{ currentMonth?.avgDealsAmountPerDay | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
									</ghost-element>
								</h3>
							</td>
							<td>
								<h3>
									<ghost-element [width]="ghostElementWidths.footerStatsHeader"
												   [height]="ghostElementHeights.h3">{{ currentMonth?.lastMonthFundings.fundingsProjected | number : '1.0-1' }}
									</ghost-element>
								</h3>
							</td>
							<td>
								<h3>
									<ghost-element [width]="ghostElementWidths.footerStatsHeader"
												   [height]="ghostElementHeights.h3">{{ currentMonth?.fundingsAmountProjected | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}
									</ghost-element>
								</h3>
							</td>
						</tr>
					</table>
				</div>
				<hr>
				<collapsible-item [isCollapsedAtFirst]="false">
					<div header-left>
						<h5>Top Five Fundings:</h5>
					</div>
					<ghost-element width="50" height="10">
						<table>
							<tr>
								<th>Internal Id</th>
								<th>Purchase Price</th>
								<th>Funding Type</th>
								<th>Law Firm</th>
							</tr>
							<tr *ngFor="let funding of currentMonth?.topFiveFundings">
								<td>
									<a [routerLink]="['/applicants', funding?.applicantId]"
									   target="_blank">{{ funding.applicantName }} ({{ funding.internalId }})</a>
								</td>
								<td>{{ funding.purchasePrice | currency : 'USD' : 'symbol' : roundValue ? '1.0-0' : '1.2-2' }}</td>
								<td>{{ funding.fundingTypeTitle }}</td>
								<td><a [routerLink]="['/law-firms', funding?.lawFirmId]"
									   target="_blank">{{ funding.lawFirmName }}</a></td>
							</tr>
						</table>
					</ghost-element>
				</collapsible-item>
			</div>
		</collapsible-item>
	</div>
</ng-container>
