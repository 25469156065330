import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { MdResizeDirective } from '@app/shared/directives/md-resize.directive';
import { mdEditorOptions } from '@app/shared/model/constants/md-editor-options';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { SalesActivityNote, TimelineNoteRequest } from '@app/shared/model/types/note';
import { SalesActivityType } from '@app/shared/model/types/sales-activity-type';
import { EntityAssociationsService } from '@app/shared/services/entity-associations.service';
import { TodoAssociationsListEntry } from '@app/todo/model/todo-associations-list-entry';
import { EntityAssociationsListEntry } from '@app/widgets/entity-associations-list/model/entity-associations-list-entry';
import { entityAssociationTypes } from '@app/widgets/entity-associations-select/entity-associations-select.model';
import { TimelineNote } from '@app/widgets/timeline-notes/model/timeline-note.model';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FundingNoteType } from '@app/shared/model/constants/funding-note-type';

@Component({
	selector: 'salesboard-modal-add-note',
	templateUrl: './salesboard-modal-add-note.component.html',
	styleUrls: ['./salesboard-modal-add-note.component.scss'],
	providers: [EntityAssociationsService]
})
export class SalesboardModalAddNoteComponent extends MdResizeDirective implements OnInit, OnDestroy {

	@Input() entity: string;
	@Input() entityId: number | string;
	@Input() isEdit: boolean;
	@Input() editNote: null | SalesActivityNote;
	@Output() onCreate = new EventEmitter<TimelineNoteRequest>();
	@Output() onUpdate = new EventEmitter<TimelineNote>();

	isSavingData = false;
	showActivityTypeSelect = false;
	selectedSalesActivities: SalesActivityType[] = [];
	salesActivityTypes: Observable<SalesActivityType[]>;
	salesActivitySummary: string | null = null;
	salesActivityDescription: string = null;
	salesActivityDate: Date | null = new Date();

	associations$: Observable<EntityAssociationsListEntry[]>;

	readonly noteAssociationTypes = entityAssociationTypes.slice(1, -1);
	readonly options = mdEditorOptions;

	private destroy$ = new Subject<void>();

	constructor(
		private bsModalRef: BsModalRef,
		private codelistRepository: CodelistRepositoryService,
		private flashMessage: FlashMessageService,
		private entityAssociationService: EntityAssociationsService
	) {
		super();
	}

	public ngOnInit(): void {
		this.associations$ = this.entityAssociationService.getAssociations$();
		this.salesActivityTypes = this.codelistRepository.get(NonUrlCodelistName.SalesActivityType);

		if (this.isEdit) {
			this.entityAssociationService.addAllAssociations(this.editNote.mirroredTo);
			this.fillInForm();
		}
	}

	private fillInForm(): void {
		this.salesActivityTypes.pipe(takeUntil(this.destroy$)).subscribe((types: SalesActivityType[]) => {
			const activityTypeIds = this.editNote.activityTypes.map(activityType => activityType.id);
			this.selectedSalesActivities = types.filter(item => activityTypeIds.includes(item.id));
		});
		this.salesActivitySummary = this.editNote.subject;
		this.salesActivityDescription = this.editNote.content;
		this.salesActivityDate = this.editNote.activityDate ? moment(this.editNote.activityDate).toDate() : null;
	}

	public toggleActivityTypeSelect(): void {
		this.showActivityTypeSelect = !this.showActivityTypeSelect;
	}

	public saveActivityNote(): void {
		this.isSavingData = true;
		const associations = this.entityAssociationService.createAssociationMirrors();
		const newActivityNote = { ...this.prepareNote(), ...associations };

		if (Object.keys(newActivityNote).some((key) => newActivityNote[key] == null)) {
			this.flashMessage.showMessage(FlashMessageType.Info, 'Please, fill in all required fields.');
			this.isSavingData = false;
		} else {
			if (this.isEdit) {
				const noteToUpdate = { ...this.editNote, ...newActivityNote };
				delete noteToUpdate.mirrored;
				delete noteToUpdate.mirroredFrom;
				delete noteToUpdate.mirroredTo;
				this.onUpdate.emit(noteToUpdate);
			} else {
				this.onCreate.emit(newActivityNote);
			}
		}
	}

	private prepareNote() {
		return {
			subject: this.salesActivitySummary,
			content: this.salesActivityDescription,
			activityTypes: this.selectedSalesActivities.length > 0
				? this.selectedSalesActivities.map(salesActivity => salesActivity.id) : null,
			activityDate: moment(this.salesActivityDate).format('Y-MM-DD'),
			systemNoteType: FundingNoteType.SalesActivity,
			[this.entity]: this.entityId,
		};
	}

	public changeSalesActivityType(salesActivityType: SalesActivityType): void {
		if (this.selectedSalesActivities.includes(salesActivityType)) {
			this.selectedSalesActivities.splice(this.selectedSalesActivities.indexOf(salesActivityType), 1);
		} else {
			this.selectedSalesActivities.push(salesActivityType);
		}
	}

	public closeModal(): void {
		this.bsModalRef.hide();
	}

	public associationSelected(event: TodoAssociationsListEntry): void {
		this.entityAssociationService.addAssociation(event);
	}

	public removeAssociation(associationToRemove: TodoAssociationsListEntry): void {
		this.entityAssociationService.removeAssociation(associationToRemove);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
