import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fieldsToFetch, fundingNoteReadOnlyFields } from '@app/applicant/applicant-detail/modals/funding-note-form-modal/funding-note-form-modal-constants';
import { FundingNoteForm } from '@app/applicant/applicant-detail/modals/funding-note-form-modal/model/funding-note-form.model';
import { getDateToFollowUp } from '@app/applicant/applicant-detail/modals/shared/applicant-detail-notes-helper';
import { FlashMessageService } from '@app/core/flash-messages/flash-message.service';
import { FlashMessageType } from '@app/core/flash-messages/flash-message/flash-message';
import { fadeInOut } from '@app/shared/animations/animations';
import { GaApiLink } from '@app/shared/ga-components/services/ga-api-link.service';
import { BsModalReason } from '@app/shared/model/constants/bs-modal-reason';
import { datePickerDefaultConfig } from '@app/shared/model/constants/date-picker-default-config';
import { mdEditorOptions } from '@app/shared/model/constants/md-editor-options';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { MedicalReachOutTarget } from '@app/shared/model/constants/notes-constants';
import { NgSelectSearchingHelperService } from '@app/shared/model/ng-select-searching-helper.service';
import { EntityId } from '@app/shared/model/types/entity-id';
import { Funding } from '@app/shared/model/types/funding';
import { FundingNote } from '@app/shared/model/types/note';
import { NoteServiceAction } from '@app/shared/model/types/note-service-model';
import { NoteService } from '@app/shared/services/note.service';
import { FundingNoteType } from '@app/shared/model/constants/funding-note-type';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, finalize, map, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'funding-note-form-modal',
	templateUrl: './funding-note-form-modal.component.html',
	styleUrls: ['./funding-note-form-modal.component.scss'],
	animations: [fadeInOut],
})
export class FundingNoteFormModalComponent implements OnInit, OnDestroy {
	@Input() fundingNote: FundingNote;
	@Input() applicantId: EntityId;
	@Output() onCloseModal = new EventEmitter<BsModalReason>();

	form: FormGroup<FundingNoteForm>;
	isFundingMedical$: Observable<boolean>;
	readonly bsConfig = {
		...datePickerDefaultConfig,
	}
	readonly contentEditorOptions = mdEditorOptions;

	fundings$: Observable<Funding[]>;
	showPortalShareWarning$ = new BehaviorSubject(false);
	complianceControl = new FormControl(false);

	readonly interactionRatingCodeList = NonUrlCodelistName.InteractionRating;
	readonly bsModalReason = BsModalReason;
	readonly medicalReachOutPossibleTargets = [
		{ label: 'Doctor', value: MedicalReachOutTarget.Doctor },
		{ label: 'Law Firm', value: MedicalReachOutTarget.LawFirm },
		{ label: 'Client', value: MedicalReachOutTarget.Client }
	];

	private readonly minDate = new Date();
	private destroy$ = new Subject<void>();

	constructor(
		public searchingHelper: NgSelectSearchingHelperService,
		private apiLink: GaApiLink,
		private bsModalRef: BsModalRef,
		private noteService: NoteService,
		private http: HttpClient,
		private flashMessage: FlashMessageService,
	) {
	}

	public ngOnInit(): void {
		this.initForm();
		this.complianceControl.setValue(this.fundingNote?.systemNoteType === FundingNoteType.Compliance);

		this.form.controls.funding.valueChanges.pipe(
			takeUntil(this.destroy$)
		).subscribe((funding) => {
			this.form.controls.dateToFollowUp.setValue(getDateToFollowUp(funding, this.minDate));
		});

		this.isFundingMedical$ = this.form.controls.funding.valueChanges.pipe(
			map((funding) => funding?.fundingType.medical),
			tap((isMedical) => {
				if (isMedical) {
					this.form.controls.medicalReachOutTarget.setValidators(Validators.required);
				} else {
					this.form.controls.rocknetVisible.setValue(false);
					this.form.controls.medicalReachOutTarget.setValue(null);
					this.form.controls.medicalReachOutTarget.removeValidators(Validators.required);
				}
				this.form.controls.medicalReachOutTarget.updateValueAndValidity();
			})
		);
	}

	private initForm(): void {
		this.form = new FormGroup({
			funding: new FormControl({
				value: this.fundingNote?.funding ?? null,
				disabled: !!this.fundingNote?.id
			}, Validators.required),
			dateToFollowUp: new FormControl(this.fundingNote?.dateToFollowUp
				? moment(this.fundingNote.dateToFollowUp).toDate() : null),
			content: new FormControl(this.fundingNote?.content ?? '', Validators.required),
			rocknetVisible: new FormControl(this.fundingNote?.rocknetVisible ?? false),
			medicalReachOutTarget: new FormControl(this.fundingNote?.medicalReachOutTarget ?? null),
			interactionRating: new FormControl(this.fundingNote?.interactionRating?.id ?? null),
		})
	}

	public loadApplicantFundings(selectedFundingId?: string): void {
		this.fundings$ = this.apiLink.retrieve<Funding>('funding', fieldsToFetch,
			{ archived: false, applicant: { id: this.applicantId } }
		).pipe(
			tap((fundings) => {
				let selectedFunding = null;
				if (fundings.length === 1) {
					selectedFunding = fundings[0];
				} else if (selectedFundingId) {
					selectedFunding = fundings.find((funding) => funding.id == selectedFundingId);
				}
				this.form.controls.funding.setValue(selectedFunding);
			})
		);
	}

	public checkPortalSharing(btnDisableLoaderFn: () => void): void {
		if (this.form.controls.rocknetVisible.value) {
			this.showPortalShareWarning$.next(true);
		} else {
			this.saveNote(btnDisableLoaderFn);
		}
	}

	public saveNote(btnDisableLoaderFn: () => void): void {
		this.hidePortalSharingWarning();
		const action = this.fundingNote?.id ? NoteServiceAction.Edit : NoteServiceAction.Add;

		this.getNoteRequest().pipe(
			finalize(() => btnDisableLoaderFn()),
			takeUntil(this.destroy$),
		).subscribe({
			next: (note) => {
				this.noteService.emitNote({
					...note,
					isFundingNote: true,
					funding: this.form.controls.funding.value,
					systemNoteType: this.complianceControl.value ? FundingNoteType.Compliance : FundingNoteType.Manual
				}, action);
				this.flashMessage.showMessage(FlashMessageType.Info, 'Note has been added.');
				this.closeModal(BsModalReason.Success);
			},
			error: () => {
				this.flashMessage.showMessage(FlashMessageType.Danger, 'There was an error processing your request.');
			}
		});
	}

	private getNoteRequest(): Observable<FundingNote> {
		const noteToSave = {
			...this.form.value,
			funding: this.form.controls.funding.value.id,
			systemNoteType: this.complianceControl.value ? FundingNoteType.Compliance : FundingNoteType.Manual
		};
		if (this.fundingNote?.id) {
			return this.apiLink.update('fundingNote', { id: this.fundingNote.id }, noteToSave, fundingNoteReadOnlyFields);
		}

		return this.http.post<FundingNote>(`/api/fundings/${noteToSave.funding}/notes`, noteToSave);
	}

	public hidePortalSharingWarning(): void {
		this.showPortalShareWarning$.next(false);
	}

	public closeModal(reason: BsModalReason): void {
		this.onCloseModal.emit(reason);
		this.bsModalRef.hide();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
