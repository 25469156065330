import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CodelistRepositoryService } from '@app/core/code-lists/codelist-repository.service';
import { fadeInOut } from '@app/shared/animations/animations';
import { NonUrlCodelistName } from '@app/shared/model/constants/non-url-codelist';
import { DateTimeHelperService } from '@app/shared/model/date-time-helper.service';
import { TimezoneItem } from '@app/shared/model/types/codelists/timezone';
import { DueDateControlData } from '@app/todo/todo-due-date/model/due-date-control-data.model';
import { TodoDueDateService } from '@app/todo/todo-due-date/service/todo-due-date.service';
import { TimezoneInputService } from '@app/todo/todo-due-date/timezone-input/service/timezone-input.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as momentTimezone from 'moment-timezone';
import { BehaviorSubject, combineLatest, map, Observable, startWith, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'todo-due-date',
	templateUrl: 'todo-due-date.component.html',
	styleUrls: ['todo-due-date.component.scss'],
	animations: [fadeInOut],
	providers: [TimezoneInputService]
})
export class TodoDueDateComponent implements OnInit, OnDestroy {
	timeControl = new FormControl<Date>({ value: null, disabled: true });
	dateControlData$ = new BehaviorSubject<DueDateControlData>({ value: null, isValid: true });
	readonly bsConfig = {
		containerClass: 'custom-datepicker-theme',
		customTodayClass: 'custom-today-class',
		minDate: moment().toDate()
	};
	private destroy$ = new Subject<void>();

	constructor(
		private timezoneInputService: TimezoneInputService,
		private todoDueDateService: TodoDueDateService,
		private codelistRepository: CodelistRepositoryService,
		private dateTimeHelper: DateTimeHelperService) {
	}

	dateValidator = (dateString: string) => {
		const momentValue = moment(dateString);
		return momentValue.isValid() &&
			momentValue.isAfter(moment().subtract(1, 'days'));
	}

	public ngOnInit(): void {

		const defaultTimezone: Observable<TimezoneItem> = this.codelistRepository.get<TimezoneItem[]>(NonUrlCodelistName.Timezone)
			.pipe(map((timezoneCodelist: TimezoneItem[]) => timezoneCodelist.find((timezone) => timezone.default)));

		combineLatest([defaultTimezone, this.dateControlData$.asObservable()])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([defaultTz, dateControlData]) => {
				if (dateControlData.value && dateControlData.isValid) {
					if (!this.timeControl.value) {
						this.timeControl.enable()
						const actualDate = new Date();
						actualDate.setHours(12, 0, 0, 0);
						this.timeControl.setValue(actualDate);
					}
					this.timezoneInputService.timezone = defaultTz;
				} else {
					this.timeControl.setValue(null);
					this.timezoneInputService.timezone = null;
					this.timeControl.disable();
				}
			});

		combineLatest([this.dateControlData$.asObservable(),
			this.timeControl.valueChanges,
			this.timezoneInputService.timezoneCtrl.valueChanges.pipe(startWith(null))])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([dateControlData, timeValue, timezoneValue]) => {
				if (dateControlData.value) {
					if (timezoneValue) {
						const dueDateString = this.dateTimeHelper.buildDateTimeString(dateControlData.value, timeValue);
						const timeZone = momentTimezone.tz(dueDateString, timezoneValue.zone) as unknown as Moment;
						this.todoDueDateService.todoDueDateString = this.dateTimeHelper.formatDateTime(timeZone.utc(), true);
					} else {
						const todoDueDateString = this.dateTimeHelper.buildDateTimeString(dateControlData.value, timeValue, true, true);
						this.todoDueDateService.todoDueDateString = todoDueDateString;
					}
					this.todoDueDateService.timezoneId = timezoneValue?.id;
				}
			})

	}

	public dateControlChanged(dateControlData: DueDateControlData): void {
		this.dateControlData$.next(dateControlData);
	}

	public clearTime(): void {
		this.timeControl.setValue(null);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
