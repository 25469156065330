import { DBCore, DBCorePutRequest, Middleware } from 'dexie';

export const updateTimestampMiddleware: Middleware<DBCore> = {
	stack: 'dbcore',
	name: 'updateTimestampMiddleware',
	create (downlevelDatabase) {
		return {
			...downlevelDatabase,
			table (tableName) {
				const downlevelTable = downlevelDatabase.table(tableName);
				if (tableName === 'timeStamps') {
					return downlevelTable;
				}
				return {
					...downlevelTable,
					mutate: req => {

						const trans = downlevelDatabase.transaction([tableName, 'timeStamps'], 'readwrite');

						const updateTimestampTable = downlevelDatabase.table('timeStamps');

						const updateResponse: DBCorePutRequest = {
							trans,
							type: 'put',
							values: [{ codelist: tableName, timestamp: new Date() }]
						};

						return Promise.all([
							downlevelTable.mutate(req),
							updateTimestampTable.mutate(updateResponse)
						]).then(([_, resp]) => resp);
					}
				}
			}
		};
	}
}
